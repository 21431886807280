import {Button, Dialog, Icon, Loading} from '@startlibs/components'
import {Errors, FormValue, SimpleRadiobox, TextInput, ToggleCheckbox, WithForm} from '@startlibs/form'
import {getColor} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {Suspense, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {CASE_CLOSED, CASE_REVIEWED, UNDER_REVIEW, WAITING_ACCEPTANCE, WAITING_MORE_INFORMATION} from '../../enums/CaseState';
import {Card, PageContainer, PageFooter, SectionHeading} from '../../components/PageLayout'
import {CaseRequestCard} from '../CaseRequestCard';
import {EmptyListPlaceholder, SearchInput} from '../../components/StyledTable'
import {ExpertProfileCard} from '../experts/components/ExpertProfileCard';
import {ExpertReviewList} from './expertReview/ExpertReviewList'
import {Header} from '../../components/Header'
import {NewExpertShareDialog} from '../NewExpertShareDialog'
import {PaymentConfirmationTag, PaymentSectionHeading} from '../../patient/utils/paymentUtils'
import {PurviewFooter} from '../../components/PurviewFooter';
import {filterExpert} from '../../utils/utils'
import {getJwt} from '../../hooks/useJwt'
import {isNotRevoked, isNotRevokedCoReview} from './expertReview/utils'
import {isPaymentPending} from '../../request/utils';
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {responseFailure} from '../../utils/validation'
import {useAskVariableExpertMoreInformation} from './hooks/useAskExpertMoreInformation';
import {lazyProviderInfo, lazyUserInfo} from '../../components/WithProvider'
import {willUseSuspense} from '../../hooks/useSuspense'
import {PendingInfo} from './info/PendingInfo'
import {IfLocale} from '../../hocs/IfLocale'
import { FormattedMessage } from 'react-intl';
import { REVIEWER_TYPE_ADMIN, REVIEWER_TYPE_EXPERT } from '../../enums/UserRole';
import { desaturate, lighten, transparentize } from 'polished';

const useAuthSuspense = willUseSuspense(() =>
  jwtGetFetcher(getJwt())(`/api/experts?nocache=${new Date().getTime()}`)
)

const useCurrentExpertsSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/experts/bycase/${requestId}`)
)

const useAllReviewersSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/reviewers?requestId=${requestId}&nocache=${new Date().getTime()}`)
)

export const RecentlyInvited = styled.span`
    color: ${getColor('gray150')};
    font-style: italic;
    font-weight: 400;
`
export const NotProvided = styled(({className}) => <i className={className}>Not provided</i>)`
  color: rgba(0,0,0,0.3);
`
const ExpertShareHeading = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      flex-grow: 1;
      margin: .5rem .5rem .5rem 0 ;
    }
    ${SearchInput} {
      flex-basis: 240px;
      flex-shrink: 0;
      margin-right: auto;
    }
`
const GoBackButton = styled(Button)`
    min-width: 7rem;
    margin-right: 1rem;
    ${Icon} {
      font-size: 13px;
    }
`

const ExpertRadioBox = styled(SimpleRadiobox)`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > div > div > div {
    position: static;
    > div {
      left: 50%;
      transform: translate(-50%,-50%);
      ${props => props.selected && css`
        background: white;
        border: transparent;
        &:before {
          background: transparent;
        }
      `}
    }
    > span {
      display: none;
    }
  }
`
const SecurityDataWarning = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: -0.25rem;
  h4 {
    font-size: 12px;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 11px;
    max-width: 36rem;
  }
`
const EmailConfirmationListContainer = styled.div`
  background-color: rgba(0,0,0,.07);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 13px;
  padding: 1rem;
  p {
    margin-bottom: 0;
  }
  ul {
    list-style-type: disc;
    margin-top: .5rem;
    margin-left: 2rem;
  }
`
const LoadingContainer = styled.div`
  height: 10rem;
  position: relative;
  background: ${getColor('gray240')};
  border-radius: 6px;
  margin-top: 2rem;
`

const AdminBadge = styled.div`
  background: #e5e5e5;
  color: #3c3c3c;
  padding: 0.5rem 1rem;
  font-size: 12px;
  border-radius: 30px;
  position: absolute;
  right: 120px;
`

const ExpertList = styled.div`
  margin-top: 1.5rem;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  overflow: hidden;
`
const StyledExpertRow = styled.div`
  cursor: pointer;
  &:nth-child(odd) {
    background-color: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))};
  }
  &:hover {
    background: ${props => transparentize(0.85, getColor('main')(props))};
  }
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 1rem;
  &:last-child {
    border-bottom: 0px;
  }
  .speciality {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 0.25rem;
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
      ${RecentlyInvited}, ${NotProvided} {
        color: white;
      }
    }
    ${Icon} {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
      color: ${getColor('main')};
      width: 10px;
      height: 11px;
      font-weight: 600;
    }
  `}
   ${props => props.highlight && !props.selected && css`
    b {
      color: ${getColor('main')};
    }  
  `}  
`

const UNLOADED = []

export const ExpertAssignmentStep = ({caseRequest, setCaseRequest}) => {

  const [rawSharedExperts, setSharedExperts] = useState(useCurrentExpertsSuspense(caseRequest.requestId))
  const sharedExperts = rawSharedExperts.filter(isNotRevoked(caseRequest))
  // const assigning = useToggle(!sharedExperts.length)
  const requestMoreinfo = useAskVariableExpertMoreInformation(caseRequest,setCaseRequest, setExperts)
  const [rawCoReviewExperts, setActiveCoReviewExperts] = useState(useCurrentExpertsSuspense(caseRequest.requestId))
  const activeCoReviewExperts = rawCoReviewExperts.filter(isNotRevokedCoReview(caseRequest))
  const assigning = useToggle((!(sharedExperts.length || activeCoReviewExperts.length)) && caseRequest.state !== CASE_CLOSED)
  useEffect(() => {
    if (!sharedExperts.length && !assigning.isOpen && !activeCoReviewExperts.length && caseRequest.state !== CASE_CLOSED ) {
      assigning.open()
    }
  }, [sharedExperts.length])

  const [experts, setExperts] = useState(UNLOADED)
  const formRef = useRef()
  const query = useToggle('')
  const newExpertDialog = useToggle()
  const confirmShareDialog = useToggle()
  const [canAdminReview, setCanAdminReview] = useState(false)
  const providerInfo = lazyProviderInfo.read()
  const userInfo = lazyUserInfo.read()

  const hasNoPaidPayment = !caseRequest.payments.find(payment => payment.paid)
  const hasAnyPendingPayment = caseRequest.payments.find(isPaymentPending)

  const action = ({selectedExpert, allowDownload, message,sendEmail}) => selectedExpert?.reviewerType === REVIEWER_TYPE_EXPERT 
    ? jwtPostFetcher(getJwt())(`/api/expert/${selectedExpert.id}/share/${caseRequest.requestId}/download/${!!allowDownload}`, {message,sendEmail})
    : jwtPostFetcher(getJwt())(`/api/admin/${selectedExpert.id}/share/${caseRequest.requestId}`, {message,sendEmail})

  const loadedExperts = useAllReviewersSuspense(caseRequest.requestId)
  useEffect(() => {
    if(loadedExperts?.length > 0){
      const admins = loadedExperts.filter(expert => expert.reviewerType === REVIEWER_TYPE_ADMIN)
      const loggedAdminAsExpert = admins.find(reviewer => reviewer.id === userInfo.id)
      var canLoggedAdminReview = loggedAdminAsExpert ? true : false
      setCanAdminReview(canLoggedAdminReview)

      if(canLoggedAdminReview){
        // should bring it to the top
        loadedExperts.unshift(loadedExperts.splice(loadedExperts.findIndex(expert => expert.id === loggedAdminAsExpert.id), 1)[0])
      }
    }
    setExperts(loadedExperts)
  }, [])
  const [availableExperts, setAvailableExperts] = useState(loadedExperts)

  useEffect(() => {
    if (activeCoReviewExperts.length > 0){
      setAvailableExperts(
        experts.filter(
          (expert) => activeCoReviewExperts.findIndex((item) => item.expert.id == expert.id ) < 0
        )
      )
    }else{
      setAvailableExperts(experts)
    }
  }, [activeCoReviewExperts.length, experts])
  
  const filteredExperts = useMemo(() =>
      query.isOpen
        ? availableExperts.filter(filterExpert(query.isOpen))
        : availableExperts
    , [query.isOpen, availableExperts])
  // const filteredExperts = query.isOpen
  //   ? availableExperts.filter(filterExpert(query.isOpen))
  //   : availableExperts

  if (!assigning.isOpen) {
    return <ExpertReviewList
      setCaseRequest={setCaseRequest}
      caseRequest={caseRequest}
      allExperts={experts}
      experts={sharedExperts}
      goToAssign={assigning.open}
      setExperts={setSharedExperts}
      providerInfo={providerInfo}
      activeCoReviewExperts={activeCoReviewExperts}
      setActiveCoReviewExperts={setActiveCoReviewExperts}
      sharedExperts={sharedExperts}
    />
  }

  const selectExpert = (expert) => () => {
    const alreadyShared = sharedExperts.find(share => share.expert.id === expert.id)
    if (alreadyShared) {
      requestMoreinfo(alreadyShared)
    } else {
      confirmShareDialog.open()
    }
  }

  return <>
    <WithForm
      onFailure={responseFailure((n,{status}) => status === 565 && "This case is shared with another expert")}
      ref={formRef}
      action={action}
      values={{allowDownload: true,sendEmail: providerInfo.notifications?.assignExpert}}
      onSuccess={({selectedExpert}, {id}) => 
        {
          const selfAssigned = userInfo.canReviewCases && userInfo.id === selectedExpert.id && selectedExpert.reviewerType === REVIEWER_TYPE_ADMIN
          confirmShareDialog.close()
          setCaseRequest(_.flow(
            _.set('state', 
              caseRequest.state === CASE_REVIEWED 
                ? WAITING_MORE_INFORMATION 
                : selfAssigned ? UNDER_REVIEW : WAITING_ACCEPTANCE
              ),
            _.set('activeCaseExpertId', id),
            _.set('activeCaseExpertType', selectedExpert.reviewerType),
            _.set('isAssignedToLoggedAdmin', selfAssigned)
          ))
          return jwtGetFetcher(getJwt())(`/api/experts/bycase/${caseRequest.requestId}`).then(setSharedExperts).then(assigning.close)
        }
      }
    >{form => <>
      <PageContainer>
        <Header title="Expert assignment"></Header>
        <PaymentSectionHeading css="align-items: flex-end;">
          <SectionHeading>
            <p>Select the existing <FormattedMessage defaultMessage="expert" description="expert word"/> you would like to assign this case to, or add a new expert:</p>
          </SectionHeading>
          <IfLocale not contains="LEGAL">
          {(hasAnyPendingPayment || hasNoPaidPayment) &&
          <PaymentConfirmationTag pending={hasNoPaidPayment || hasAnyPendingPayment} css="margin-bottom: 1rem;">
            {hasAnyPendingPayment ?
              <span>There is a payment due. Waiting on confirmation.</span>
              : <span>No payment has been made.</span>
            }
            <Icon icon="failure"/>
          </PaymentConfirmationTag>
          }
          </IfLocale>
        </PaymentSectionHeading>
        <Card>
          <ExpertShareHeading>
            {activeCoReviewExperts.length > 0 && <GoBackButton icon="arrow-left" onClick={() => {assigning.close()}}>Back</GoBackButton>}
            <SearchInput>
              <Icon icon="search"/>
              <TextInput placeholder="Search" raw value={query.isOpen} setValue={query.openWith}/>
            </SearchInput>
            <Button
              disabled={experts === UNLOADED}
              onClick={_.flow(query.willOpenWith(""), newExpertDialog.open)}
              icon="plus-circle"
            >Add expert</Button>
          </ExpertShareHeading>
          <Suspense fallback={<LoadingContainer><Loading absolute/></LoadingContainer>}>
            <ExpertTable filteredExperts={filteredExperts} experts={experts} setExperts={setExperts} canAdminReview={canAdminReview} userInfo={userInfo}/>
          </Suspense>
          <ToggleCheckbox
            label={<b>Enable expert to download image files from this case</b>}
            path="allowDownload"
            css="margin:2rem 0 0;"
          />
        </Card>
        <PageFooter>
          {
            sharedExperts.length > 0 && <Button onClick={assigning.close}>Cancel</Button>
          }
          <FormValue path="selectedExpert">{expert =>
            <Button
              disabled={!expert}
              highlight
              onClick={selectExpert(expert)}
            >Assign case to selected expert</Button>
          }</FormValue>
        </PageFooter>
        {
          confirmShareDialog.isOpen &&
          <ConfirmShareDialog
            caseRequest={caseRequest} form={form} setExperts={setExperts} hasNoPaidPayment={hasNoPaidPayment}
            hasAnyPendingPayment={hasAnyPendingPayment}
            closeDialog={confirmShareDialog.close} outerForm={form}
            providerInfo={providerInfo}
            userInfo={userInfo}
          />
        }
      </PageContainer>
      <PurviewFooter/>
    </>
    }</WithForm>
    {
      newExpertDialog.isOpen &&
      <NewExpertShareDialog
        setExperts={setExperts}
        closeDialog={newExpertDialog.close}
        selectExpert={(expert) => formRef.current.setValue('selectedExpert', expert)}
      />
    }
  </>
}

const ExpertTable = ({filteredExperts, experts, setExperts, canAdminReview, userInfo}) => {

  return filteredExperts.length > 0 && experts.length > 0 ?
    <ExpertList>
      {
        filteredExperts.map((expert, index) => {
          return <FormValue path="selectedExpert">{(selectedExpert, setSelectedExpert) =>
            <ExpertRow
              selected={selectedExpert === expert} setSelectedExpert={setSelectedExpert}
              expert={expert} highlight={canAdminReview && expert.reviewerType === REVIEWER_TYPE_ADMIN && expert.id === userInfo.id}
            />
          }</FormValue>
        }
        )
      }
    </ExpertList>
    : (
      <EmptyListPlaceholder>
        {experts.length > 0 && filteredExperts.length === 0 &&
        <span>There are no experts matching your search criteria.</span>}
        {experts.length === 0 && <span>There are no experts registered in this system yet.</span>}
      </EmptyListPlaceholder>
    )
}

const ConfirmShareDialog = ({closeDialog, form, caseRequest, providerInfo, userInfo}) => {

  const isSelfAssignment = form.getValue("selectedExpert")?.reviewerType === REVIEWER_TYPE_ADMIN && form.getValue("selectedExpert")?.id === userInfo.id
  if(isSelfAssignment) {
    form.setValue("sendEmail", false)
  }

  return <Dialog
    title="Assign case"
    closeDialog={closeDialog}
    isLoading={false}
    footer={<>
      <Button onClick={closeDialog}>Cancel</Button>
      <Button
        autoFocus
        highlight
        isLoading={form.isLoading}
        type="submit"
        onClick={form.willSubmitForm}
      >Assign case</Button>
    </>}
  >
    <p>Please review the details below:</p>
    <CaseRequestCard caseRequest={caseRequest}/>
    <p>Assigning case to:</p>
    <ExpertProfileCard expert={form.getValue("selectedExpert")}/>
    {!isSelfAssignment && <>
      <ToggleCheckbox
        label={<strong>Notify expert that the case was assigned</strong>}
        path="sendEmail"
      />
      <FormValue path="sendEmail">{sendEmail => sendEmail && <>
        <TextInput
          path="message"
          label="Message"
          descText="This will be included in the email sent to the expert."
          textarea
          autoResize
        />
        <SecurityDataWarning>
          <h4>This email may contain confidential and protected health care information.</h4>
            <p>
              Please be sure that the email of the recipient has been entered
              correctly and that you are using appropriately confidential mechanisms
              for this communication.
            </p>
        </SecurityDataWarning>
      </>}</FormValue>
    </>}

    <PendingInfo caseRequest={caseRequest} hideServiceTerms={!providerInfo.serviceTerms || !providerInfo.requiresAgreement}/>
    <Errors/>
  </Dialog>
}


const ExpertRow = ({selected, expert, setSelectedExpert, highlight}) => {
  const expertName = expert.firstName + " " + expert.lastName
 
  return <StyledExpertRow selected={selected} onClick={() => setSelectedExpert(expert)} highlight={highlight} title={highlight ? 'Selfassignment' : ''}>
    <div>
      <div>
        {expertName.length > 2 ?
          <><b>{expertName}</b> ({expert.expertEmail})</>
          :
          expert.expertEmail
        }</div>
      <IfLocale not contains="LEGAL">
        {expert.specialization?.length > 0 &&
          <div className="speciality">Speciality: {(expert.specialization || []).join(", ")}</div>
        }</IfLocale>
    </div>
    {expert.reviewerType === REVIEWER_TYPE_ADMIN && <AdminBadge>
      Administrator
    </AdminBadge>}
    <div className="check" css="position:relative">
      <ExpertRadioBox selected={selected} path="selectedExpert" fieldValue={expert}/>
      {selected && <Icon icon="check"/>}
    </div>
  </StyledExpertRow>
}
