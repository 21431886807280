import { desaturate, transparentize } from 'polished';
import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash/fp'
import lighten from 'polished/lib/color/lighten'
import styled, {css} from 'styled-components'

import {Button, Dialog, Icon, Loading, willUseIsSticky, Tooltip, Popup} from '@startlibs/components';
import {ConfirmDialog, Errors, TextInput, useConfirmDialog, useProvideErrors} from '@startlibs/form'
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd'
import {callIfFunction, customTheme, getColor, smoothScroll} from '@startlibs/utils'
import {useLazyConstant, usePrevious, useRefState, useToggle} from '@startlibs/core';
import {usePopupToggle} from '@startlibs/core';

import {ALL_NON_COMPLIANT_DICOM} from '../enums/MetaRecordType'
import {ActivityLog} from './dialogs/ActivityLog'
import {
  AperioSVS,
  CCDA,
  DicomStudy,
  HamamatsuNDPI,
  HamamatsuVMS,
  HamamatsuVMU,
  Leica,
  Mirax,
  NonCompliantDicom,
  PhilipsiSyntax,
  TIF,
  TIFF,
  VentanaBIF
} from '../enums/RecordFormat';
import { AttachmentActions, AttachmentBox, AttachmentDescription, AttachmentDetails, AttachmentIcon, AttachmentInfoContainer, FileNotFoundErrorBox, TextButton } from './AttachmentBoxStyles';
import {
  Canceled,
  Deleted, FileNotFound, Quarantined,
  Shortlisted,
  Uploaded,
  Uploading,
  Waiting
} from '../enums/FileState'
import { Clinical, Other, Pathology, Radiology, Unidentified } from '../enums/RecordClass';
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';
import {DicomBox} from './DicomBox'
import {
  FileParser,
  filterIsNonDicom,
  filterIsValidNonDicom,
  isOtherKnowExtension,
  notIgnoredFiles,
  PATHOLOGY_EXTENSIONS
} from '../dicom/FileParser'
import { LinkVivaStudies } from './dialogs/LinkVivaStudies';
import {NonCompliantBox} from './NonCompliantBox'
import {NonDicomBox} from './NonDicomBox'
import {PathologyMultiFilebox} from './PathologyMultiFileBox'
import {ProcessingBox} from './ProcessingBox'
import {Routing, Submitted} from '../enums/RecordState'
import {TransitionDiv} from '../utils/TransitionDiv'
import { UploaderHeader, UploaderHeaderComponent } from './UploaderHeader';
import {
  addDicomInstance,
  addNonCompliantInstance,
  createAttachment,
  getFileNameAndExtension,
  getNonCompliantInstanceFile,
  removeDicomInstance,
  removeDicomStudy,
  removeFile,
  removeNomCompliantInstance,
  removeRecord,
  removeUnploadedInstances,
  removeUnploadedNonCompliant,
  updateDicomInstance,
  updateDicomStudy,
  updateFile,
  updateMultiFileInstance,
  updateNonCompliantInstance,
  updateNonCompliantInstanceSource,
  updateRecord
} from '../utils/AttachmentsUtils';
import {addToRetryFilesNotfound, useFilesNotFoundRetry} from './hooks/useFilesNotFound'
import { discardMacOSApps } from '../utils/fileUtils';
import {getFileInstanceList} from '../dicom/FileEventToList'
import {getMultiAttachmentState} from '../utils/businessUtils'
import {instanceFromServerAttributes} from '../dicom/DicomInstance'
import {jwtPostFetcher} from '../utils/authFetch'
import {parseMultiFilePathology} from './processing/MultiFilePathologyParser'
import {updateActivityLogBy,updateActivityLogFile} from './hooks/useActivityLog'
import {useDropFileEvents} from '../hooks/useDropFileEvents'
import {useGetState} from '../hooks/useAsyncState'
import { closeNotes } from './recordGroup/NotesField';
import { UnidentifiedFilesWindow } from './dialogs/UnidentifiedFilesWindow';
import { ADMIN, EXPERT, PATIENT, PROVIDER } from '../enums/UserRoles';
import {AddButton, FileInputBoxStyle, UploaderHeading, UploadStatusIcon} from "./styled/UploaderStyled";

const exception = []
const editingName = ''
const newVartest = ''
const GroupContainer = styled.div`
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    :last-child {
      margin-bottom: -1px;
    }
  `}
  ${props => props.isNotClassMinified === true && css`
    background: white;
    margin-bottom: 1px !important;
    border-radius: 8px;
    border: 1px solid ${getColor('gray210')};
    padding: 0.5rem 0;
    ${AttachmentBox} {
      :first-child {
        border-radius: 6px 6px 0 0;
        margin-top: 0.5rem;
      }
      :last-child {
        border-radius: 0 0 6px 6px;
        border-bottom: none;
      }
    }
  `}
`

const GroupContent = styled.div`
  position: relative;
`

const GroupWrapper = styled.div`
  position: relative;
  border: 0.5rem solid transparent;
  border-top-width: 1rem;
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    margin: 0rem 0.5rem;
    border: 0px;
  `
  }
  ${props => props.isDragging && css`
    &:before {
      content: '';
      position: absolute;
      top: -.5rem;
      right: -.5rem;
      left: -.5rem;
      bottom: -.5rem;
      background: white;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
      padding: .5rem .5rem 1rem;
      z-index: 1;
    }
    ${GroupContent} {
      z-index: 5;
    }
  `}
  ${props => props.emptyGroup && css`
    border-top: none;
    border-bottom: none;
  `}
`
const DraggableIcon = styled(Icon)`
  padding: 4px 0.25rem 0.5rem 4px;
  font-size: 16px;
  color: rgba(0,0,0,0.3);
  cursor: grab;
  vertical-align: -3px;
  outline: none;
  :hover {
    color: rgba(0,0,0,0.5);
  }
`

const GroupHeading = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${getColor('gray150')};;
  margin-bottom: 1.5rem;
  .nameWrapper {
    padding: 0.25rem 0.5rem 0.25rem 0;
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    ${Icon} {
      display: none;
      font-size: 15px;
      vertical-align: -3px;
    }
  }
  .line {
    flex-grow: 1;
    border-bottom: 1px solid ${getColor('gray210')};
    flex-grow: 1;
    min-width: 150px;
  }
  .collapseOption {
    margin-left: 0.75rem;
  }
  ${props => props.canEdit && css`
    .nameWrapper {
      padding: 0.25rem 0.5rem;
      :hover {
        cursor: pointer;
        background: ${getColor('gray240')};
      }
      ${Icon} {
        display: inline-block;
      }
    }
    .collapseOption {
      margin-right: 0.5rem;
    }
  `}
  ${Button} {
    margin-left: .5rem;
  }
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    margin-bottom: 0px;
  `}
  ${customTheme("GroupHeading")};
`

const EmptyGroupArea = styled.div`
  min-height: 2rem;
  color: rgba(0,0,0,0.25);
  text-align: center;
  margin-top: 1rem;
  a {
    text-decoration: underline;
    color: rgba(0,0,0,0.4);
    :hover, :active {
      color: ${getColor('alert')};
    }
  }
`


const ViewAllButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  min-height: 2rem;
  color: ${getColor('gray90')};
  a {
    margin-left: 0.75rem;
  }
  ${Icon} {
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 24px;
    color: ${getColor('gray180')};
    cursor: pointer;
    :hover {
      color: ${getColor('gray150')};
    }
  }
  ${Loading} {
    margin-right: 0.75rem;
  }
`

const HelpPopup = styled(Popup)`
  max-width: 330px;
  font-size: 12px;
  border-radius: 6px;
  padding: 1.5rem;
`


const fileParser = () => {}

export const EnhancedRecordClassList = [Pathology,Radiology,Clinical]

const PROCESS_STATES = {
  STARTED: 1,
  FINISHED: 2,
  FOUND_FILES: 3,
  FOUND_INVALID_FILES: 4,
  FOUND_REPEATED_DICOM: 5,
}

var synchonousUpload = window.safari
if (window.navigator.userAgent.indexOf("Chrome") > -1) {
  // "Google Chrome or Chromium"
} else if (window.navigator.userAgent.indexOf("Safari") > -1) {
  // "Apple Safari"
  synchonousUpload = true
}

const getRowsForRecords = (records) => {
  const [recordsWithoutNonCompliant,nonCompliantRecords] = _.partition(({format}) => format !== NonCompliantDicom,records)
  return recordsWithoutNonCompliant
    .concat( nonCompliantRecords.length ? {nonCompliant:true,key:NonCompliantDicom,recordClass:Radiology, state: getMultiAttachmentState(nonCompliantRecords), instances: nonCompliantRecords} : [])
}

const isOfRecordClass = (recordClass) => (attachment) =>
   attachment?.recordClass === recordClass

const isPathologyFile = (file) => {
  const [name,extesnion] = getFileNameAndExtension(file)
  return PATHOLOGY_EXTENSIONS.indexOf(extesnion?.toLowerCase())>=0
}

export const isMultiFile = recordFormat => [Mirax,HamamatsuVMS,HamamatsuVMU].indexOf(recordFormat)>=0
export const isMinified = (mode) => (mode === DEVICE || mode === DISK);

const setAttachmentClassAndFormat = (attachment) => {
  if (['pdf','txt'].indexOf(attachment?.fileExtension?.toLowerCase())>=0) {
    return {...attachment,recordClass:Clinical,format:attachment.fileExtension.toUpperCase()}
  } else {
    return {...attachment,recordClass: Other, format: Other}
  }
}

const getItemSessionUID = (item) => {
  return (item.key || item.recordUID)+""
}
const getItemPersistedUID = (item) => {
  return (item.recordUID || item.key)+""
}

const setPathologyClassAndFormat = (attachment) => {
  const extension = attachment.fileExtension.toLowerCase()
  const formats = {
    'tif':TIF,
    'tiff':TIFF,
    'isyntax':PhilipsiSyntax,
    'ndpi':HamamatsuNDPI,
    'svs':AperioSVS,
    'svslide':AperioSVS,
    "bif": VentanaBIF,
    "scn": Leica,
    "vms": HamamatsuVMS,
    "vmu": HamamatsuVMU
  }
  const format = formats[extension] || TIF
  return _.flow(
    _.set('recordClass',Pathology),
    _.set('format',format)
  )(attachment)
}

export const FileinputBox = React.forwardRef
(({
    jwt,
    appJwt,
    allowDownload = true,
    allowDownloadMedicalImages = true,
    withViewAllButton,
    persistRecord,
    persistRecordInfo,
    persistGroups,
    removePersistedRecord,
    removeSelectedRecords,
    disabled,
    listMode,
    medicalRecords,
    setNotification,
    parallelFiles = 2,
    isApp,
    groups,
    withoutDelete,
    allowReorder,
    canAddGroup,
    role,
    hasMRN,
    canMoveGroup = (group,groups) => groups.length > 2,
    autoGrouping,
    apiEndpoints = {dicomViewer: undefined, nonDicomViewer: undefined, shortTokenUrl: undefined, downloadRecord: undefined, loadDownloadRecord: undefined, storageHost: undefined, pdfIframeSrc: '', downloadFiles: undefined},
    patientName = '',
    linkedStudies, 
    setLinkedStudies,
    canOpenWorklist,
    providerJwt,
    worklistViewerJwt,
    mode,
    setIsUploading = () => false,
    setToBeUploaded = () => 0,
    requestId,
    setTotal = () => 0,
    total,
    refreshUrl,
    caseId,
    canAddHiddenFileToCase = true
  }, ref) => {

  const [isSelectMode, setSelectMode] = useState(false)
  const [isSessionOnly, setSessionOnly] = useState(false)
  const [unprocessedFiles, setUnprocessedFiles, getUnprocessedFiles] = useGetState([])

  const [records, setRecords, getRecords] = useGetState(medicalRecords?.map(_.update('instances', (instances) => instances || [])) || [], (next,prev) => {
    const newRecords = getRowsForRecords(_.differenceBy(getItemPersistedUID,next,prev))
  })

  const [attachmentGroups, rawSetAttachmentGroups, getAttachmentGroups] = useGetState(groups || EnhancedRecordClassList.map(key =>
    ({id:key,name:key,items:[]})
  ))
  const setAttachmentGroups = (...args) => rawSetAttachmentGroups(...args)
  const isCollapsedRef = useRefState([])

  const uploadingFiles = useRefState([],(prev,next) => {
    const newFiles = _.difference(next,prev)
    newFiles.map(file => updateActivityLogFile(file.sourceFile,Uploading))
  })
  const failedFiles = useRefState([])
  const pendingFiles = useRefState([],(prev,next) => {
    const newFiles = _.difference(next,prev)
    // if (newFiles.length) {
    //   setAttachmentGroups((groups) => {
    //     const inGroups = _.flatten(groups.map(_.get('items')))
    //     const notInGroups = newFiles.filter(attachment => inGroups.indexOf(getItemSessionUID(attachment)) < 0)
    //     if (notInGroups.length) {
    //       return _.update([0, 'items'], _.union(_, notInGroups.map(getItemSessionUID)),groups)
    //     } else {
    //       return groups
    //     }
    //   })
    // }
    newFiles.map(file => updateActivityLogFile(file.sourceFile,Waiting,file))
  })

  const processingFile = useRefState()

  const existingDicomInstances = useRefState([])

  const processingState = useRefState(PROCESS_STATES.FINISHED)
  const invalidFiles = useRefState([])
  const retryFilesNotFound = useFilesNotFoundRetry()

  const cancelProcessDialog = useToggle()
  const processingTimeout = useToggle()

  const confirmCancelUploads = useToggle()

  const [selectedRecords, setSelectedRecords] = useState([])

  const [isDraggingOver, dragBoxRef] = useDropFileEvents((e) => {
    processingTimeout.open()
    setFile(e)
  })

  const resolveConfirmRef = useRefState()
  React.useImperativeHandle(ref, () => ({
    confirm: () => {
      if (uploadingFiles.get().length || pendingFiles.get().length || failedFiles.get().length) {
        return new Promise((res) => {
          resolveConfirmRef.set(() => () => {
            confirmCancelUploads.close()
            res()
          })
          confirmCancelUploads.open()
        })
      } else {
        return Promise.resolve()
      }
    }
  }), [])


  const setFile = (e) => {
    e.stopPropagation()
    e.preventDefault()
    getFileInstanceList(e)
      .then(dirtyNewFilesAndPaths => {
        const newFilesAndPaths = discardMacOSApps(dirtyNewFilesAndPaths)
        const [pathologyRecords,discardedFiles] = parseMultiFilePathology(newFilesAndPaths,mode)
        const uploadingFiles = _.flatMap(({instances}) => instances.map(_.get('sourceFile')),pathologyRecords)
        const removeFiles = uploadingFiles.concat(discardedFiles)
        setRecords(_.concat(pathologyRecords))
        pendingFiles.set(_.concat(_.flatMap(_.get('instances'),pathologyRecords)))
        return newFilesAndPaths.filter(({file})=> removeFiles.indexOf(file)<0).map(_.get("file"))
      })
      .then((files) => {
        const newFiles = files
        // const pathologyRecords = newFiles.filter(isPathologyFile).map(_.flow(createAttachment,setPathologyClassAndFormat))
        const pathologyRecords = newFiles.filter(isPathologyFile).map((item) =>  _.flow((item) => createAttachment(item,mode),(item) => setPathologyClassAndFormat(item))(item))
        const nonPathologyFiles = newFiles.filter(f => !isPathologyFile(f))
        const filesNotIgnored = nonPathologyFiles.filter(notIgnoredFiles)
        const nonDicom = filesNotIgnored.filter(filterIsNonDicom).filter(filterIsValidNonDicom).map( (item) =>  _.flow((item) => createAttachment(item,mode),(item) => setAttachmentClassAndFormat(item))(item))
        const asyncCheckFiles = filesNotIgnored.filter((file) => !filterIsNonDicom(file) || !filterIsValidNonDicom(file))
        processingTimeout.closeAfter(2000)
        setUnprocessedFiles(files => _.orderBy(['size'], ['asc'], files.concat(asyncCheckFiles)))
        setRecords(files => _.orderBy(['size'], ['asc'], [...nonDicom,...pathologyRecords, ...files]))
        pendingFiles.set(f => f.concat(nonDicom,...pathologyRecords))
      })
      .catch(console.log)
    isDraggingOver.close()
    if (e.target && e.target.value) {
      e.target.value = ""
    }
  }

  const addNonDicomAttachment = (file,update = _.identity) => {
    const attachment = update(createAttachment(file,mode))
    setRecords(_.concat([attachment]))
    pendingFiles.set(_.concat([attachment]))
  }

  const processFile = (nextFile) => {
    const updateProcessState = (newState) => (currentState) => (currentState === PROCESS_STATES.STARTED || currentState === PROCESS_STATES.FOUND_REPEATED_DICOM) ? newState : currentState
    return fileParser(nextFile, uploadingFiles.get().length < parallelFiles)
      .then(({file, dicomInstance, byteArray, ccda, isValid}) => {
        if (ccda) {
          processingState.set(updateProcessState(PROCESS_STATES.FOUND_FILES))
          if (isValid) {
            addNonDicomAttachment(file,_.flow(_.set('recordClass',Clinical),_.set('format',CCDA)))
          } else {
            // console.log("XML Files")
            addNonDicomAttachment(file,_.flow(_.set('recordClass',Other),_.set('format',Other))) 
          }
        } else if (dicomInstance) {
          if (dicomInstance.isCompliant) {
            setRecords(addDicomInstance(dicomInstance, file, byteArray, mode))
          } else {
            if (!getRecords().find(({instanceUID}) => instanceUID === dicomInstance.instanceUID)) {
              setRecords(addNonCompliantInstance(dicomInstance, file, byteArray))
            } else {
              processingState.set(updateProcessState(PROCESS_STATES.FOUND_REPEATED_DICOM))
              return;
            }
          }
          if (existingDicomInstances.get().indexOf(dicomInstance.instanceUID) < 0) {
            if (byteArray && uploadingFiles.get().length < parallelFiles) {
              uploadingFiles.set(_.concat([{...dicomInstance, sourceFile: file}]))
            } else {
              pendingFiles.set(_.concat([{...dicomInstance, sourceFile: file}]))
            }
            existingDicomInstances.set(_.concat(dicomInstance.instanceUID))
            processingState.set(updateProcessState(PROCESS_STATES.FOUND_FILES))
          } else {
            processingState.set(updateProcessState(PROCESS_STATES.FOUND_REPEATED_DICOM))
          }
        } else {
          processingState.set(updateProcessState(PROCESS_STATES.FOUND_FILES))
          // 'Normal non dicoms - whitelist'
          addNonDicomAttachment(file,_.flow(_.set('recordClass',Other),_.set('format',Other)))
        }
      })
      .catch(e => {
        // if (e && e.nonReadable) {
        //   return {retry:true}
        if (e?.fileNotFound) {
            updateActivityLogFile(nextFile,FileNotFound)
            addToRetryFilesNotfound(() => setFile({preventDefault:_.identity,stopPropagation:_.identity,target:{files:[nextFile]}}))
        } else if (e?.invalidExtension) {
          updateActivityLogFile(nextFile,Shortlisted)
          invalidFiles.set(_.concat(nextFile))
          processingState.set(PROCESS_STATES.FOUND_INVALID_FILES)
        } else if (e?.nonCompliant) {
          const attachment = createAttachment(nextFile)
          setRecords(addNonCompliantInstance(attachment, nextFile))
          pendingFiles.set(_.concat([{...attachment, nonCompliant: true, sourceFile: nextFile}]))
        } else if (!e || !e.empty) {
          processingState.set(updateProcessState(PROCESS_STATES.FOUND_FILES))
          // 'Different NonDicoms - not on whitelist'
          addNonDicomAttachment(nextFile,_.flow(_.set('recordClass',Other),_.set('format',Other)))
        } else {
          updateActivityLogFile(nextFile,Shortlisted)
          processingState.set(PROCESS_STATES.FOUND_INVALID_FILES)
        }
      })
  }

  const startNextUpload = () => {

    let allFiles = _.concat(pendingFiles.get(),uploadingFiles.get())
    // DICOM and Pathology Multi file records are resumed to one record, but have many files being uploaded.
    // Then one must resume it to one instance base on unique identifiers
    // But the identifer may not be present in different types
    let reduced = _.concat(
      _.uniqBy('key', allFiles).filter((item) => item.key),
      _.concat(
        _.uniqBy('recordKey', allFiles).filter((item) => item.recordKey),
        _.uniqBy('studyUID', allFiles).filter((item) => item.studyUID)
      )
    )
    setToBeUploaded(reduced.length)
    setIsUploading(uploadingFiles.get().length > 0)
    
    if(recordRows.filter((item) => item.mode === mode).length > 0){
      setTotal(recordRows.filter((item) => item.mode === mode).length)
    }
    
    if (uploadingFiles.get().length >= parallelFiles || !pendingFiles.get().length) {
      if (getUnprocessedFiles().length) {
        processAndUpload()
      }
      return
    }
    const attachment = _.head(pendingFiles.get())
    pendingFiles.set(_.without([attachment]))
    uploadingFiles.set(_.concat(attachment))
    const updater = _.set('fileToUpload', attachment.sourceFile)
    if (attachment.isCompliant) {
      setRecords(updateDicomInstance(attachment, updater))
    } else if (attachment.isCompliant === false || attachment.nonCompliant) {
      setRecords(updateNonCompliantInstance(attachment, updater))
    } else if (attachment.multiFile) {
      setRecords(updateMultiFileInstance(attachment, updater))
    } else {
      setRecords(updateFile(attachment, updater))
    }
    processAndUpload()
  }

  const processAndUpload = () => {
    if (processingFile.get() || cancelProcessDialog.get()) {
      return
    }
    // if (synchonousUpload) {
    //   if (uploadingFiles.get().length) {
    //     return
    //   }
    // }
    if (getUnprocessedFiles().length) {
      const nextFile = _.head(getUnprocessedFiles())
      processingFile.set(nextFile)
      processFile(nextFile).finally(() => {
        processingFile.set(false)
        setUnprocessedFiles(_.without([nextFile]))
        startNextUpload()
      })
    }
    startNextUpload()
  }


  useEffect(() => {
    if (unprocessedFiles.length === 0) {
      if (processingState.get() === PROCESS_STATES.FOUND_REPEATED_DICOM) {
        setNotification("DICOM files selected to upload are already attached to this request.")
      }
      if (invalidFiles.get().length) {
        if (invalidFiles.get().length === 1) {
          // setError("fileType", invalidFiles.get()[0].name + " could not be uploaded. File type not supported.")
        } else {
          // setError("fileType", invalidFiles.get().length + " files could not be uploaded due to file types not being supported.")
        }
        invalidFiles.set([])
      }
      processingState.set(PROCESS_STATES.STARTED)
    }
    if (unprocessedFiles.length && processingState.get() === PROCESS_STATES.FINISHED) {
      processingState.set(PROCESS_STATES.STARTED)
    }
    processAndUpload()
  }, [unprocessedFiles, cancelProcessDialog.isOpen])


  const uploadsProcessing = uploadingFiles.get().length
  const onlyFailedUploads = !uploadsProcessing && failedFiles.get().length

  const onDragEnd = () => {}

  const moveRecordTo = (recordIndex, {from,to}) => {
    onDragEnd({
        source:{droppableId:from, index:recordIndex},
        destination:{droppableId: to, index: attachmentGroups.find(_.matchesProperty('id',to))?.items?.length || 0}
    })
  }

  const updateMultipleRecordState = (state,records) => {
    const recordUIDS = _.flatMap(({recordUID,instances}) => recordUID || instances.map(_.get('recordUID')),records)
    return persistRecordInfo({recordUID:recordUIDS.join('-'),state},{state:'toUpdate'})
      .then(() => {
        setRecords(_.map(record => recordUIDS.indexOf(record.recordUID) >= 0 ? _.set('state',state,record) : record))
      })
  }

  const startDownstream = () => {
    return jwtPostFetcher(jwt)("/routingOrder/initDownstream")
      .then((ids) => {
        setRecords(_.map(record => ids.indexOf(record.recordUID) >= 0 ? _.set('state',Routing,record) : record))
      })
  }

  const uploaderUtils = useLazyConstant(() => ({
    setRecords,
    getRecords,
    persistRecord,
    persistRecordInfo,
    removePersistedRecord,
    removeSelectedRecords,
    existingDicomInstances,
    pendingFiles,
    failedFiles,
    isCollapsedRef,
    updateMultipleRecordState,
    startNextUpload,
    setAttachmentGroups,
    uploadingFiles
  }))

  const recordRows = getRowsForRecords(records)
  const flatAttachments = recordRows.map(getItemPersistedUID)
  const prevFlatAttachment = usePrevious(flatAttachments)

  const tempRecordsObject = []
  attachmentGroups.find(g => g.id === 'notclass')?.items?.map(item => tempRecordsObject.push({'recordUID': Number(item)}))
  const medicalRecordsRow = _.differenceBy(_.property('recordUID'), medicalRecords,  tempRecordsObject )
  useEffect(() => {

    if (autoGrouping && !flatAttachments.length && (groups && 
        (
          (!groups.length) || 
          (
            (groups.length == 1 && ( (groups[0].id === "ungrouped") || (groups[0].id === "notclass") ) )
            ||
            (groups.length == 2 && groups.filter(g => g.id === "ungrouped") && groups.filter(g => g.id === "notclass"))
          )
        )
    )) {
      rawSetAttachmentGroups(
        EnhancedRecordClassList.map(key =>
          ({id:key,name:key,items:[]})
        )
      )
    }

    // if ((autoGrouping && !groups.length) || (autoGrouping && groups.length == 1 && groups[0].id === "ungrouped")){
    //   rawSetAttachmentGroups(
    //     EnhancedRecordClassList.map(key =>
    //       ({id:key,name:key,items:[]})
    //     )
    //   )
    // }

    if (flatAttachments.length < prevFlatAttachment?.length) {
      setAttachmentGroups(_.map(_.update('items',_.filter(id => flatAttachments.indexOf(id) >= 0))))
    }
  },[flatAttachments.join(";")])

  // const canManageNotClassFiles =  ((role === PATIENT && mode && mode?.length > 0) || (role === ADMIN)) && canAddHiddenFileToCase
  const canManageNotClassFiles =  ((role === PATIENT && mode && mode?.length > 0) || (role === ADMIN))

  const previousAttachmentGroups = usePrevious(attachmentGroups)
  useEffect(() => {
    const clearTemps = _.map(_.update('items',_.filter(item => item.indexOf("temp-")<0)))
    const removeSize = _.map(_.unset('size'))

    // if (allowReorder && previousAttachmentGroups && JSON.stringify(clearTemps(attachmentGroups)) !== JSON.stringify(clearTemps(previousAttachmentGroups))) {
    if (canManageNotClassFiles && previousAttachmentGroups && JSON.stringify(clearTemps(attachmentGroups)) !== JSON.stringify(clearTemps(previousAttachmentGroups))) {
      const retry = () => persistGroups(clearTemps(removeSize(getAttachmentGroups())))
        .catch(() =>
          setNotification({type:"alert", timeout: 0,msg:(close) => <span>Could not save record order <a onClick={() => { close(); retry()}}>Retry</a></span>})
        )
      retry()
    }
  },[attachmentGroups])

  const refreshGroups = (attachmentGroups) => {
    const clearTemps = _.map(_.update('items',_.filter(item => item.indexOf("temp-")<0)))
    const removeSize = _.map(_.unset('size'))

    const retry = () => persistGroups(clearTemps(removeSize(attachmentGroups)))
      .catch(() =>
        setNotification({type:"alert", timeout: 0,msg:(close) => <span>Could not refresh groups <a onClick={() => { close(); retry()}}>Retry</a></span>})
      )
    retry()
    
  }


  const activityLog = useToggle()
  const unidentifiedFilesToggle = useToggle()
  let css = `background: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))}; margin:-0.5rem; margin-top: 4px;margin: 0.5rem 0rem; border-radius: 8px;`;
  let cssBorder = css + "border: 1px solid lightgray;";

  const [unidentifiedFilesPatient, setUnidentifiedFilesPatient] = useState(uploaderUtils.getRecords().filter((item) => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item)))
  const unidentifiedUploadingFilesPatient = uploaderUtils.uploadingFiles.get().filter((item) => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item))
  const isUploading = uploaderUtils.uploadingFiles.get().length > 0
  const identifiedFiles = _.difference(uploaderUtils.getRecords(),unidentifiedFilesPatient)

  // This is used to refresh Hidden Files count
  useEffect(() => {
    const notClassGroup = attachmentGroups.filter(g => g.id === 'notclass')[0]?.items
    setUnidentifiedFilesPatient(uploaderUtils.getRecords().filter((item) => 
      item.mode === mode 
      && !isOtherKnowExtension(item) 
      && !isOfRecordClass(Pathology)(item) 
      && !isOfRecordClass(Radiology)(item) 
      && notClassGroup?.indexOf(item.recordUID+'') >= 0))
  },[uploaderUtils.getRecords(), attachmentGroups])

  return <>


    <div hidden={activityLog.isOpen/* || importStudies.isOpen*/}>
      {/* {((!listMode) || allowDownload)  */}

    {/* <DragDropContext nonce={window.__webpack_nonce__} onDragEnd={onDragEnd}></DragDropContext> */}




    </div>
  </>
})


const AttachmentGroup =
  ({
      group,
      index,
      groupsLength,
      jwt,
      appJwt,
      disabled,
      withoutDelete,
      isApp,
      moveRecordTo,
      unprocessedFiles,
      allowDownload,
      allowDownloadMedicalImages,
      uploaderUtils,
      records,
      setRecords,
      canMoveGroup,
      flatAttachments,
      allowReorder,
      canAddGroup,
      listMode,
      role,
      apiEndpoints,
      groupActionButton,
      linkedStudies,
      setLinkedStudies,
      setNotification,
      attachmentGroups,
      setAttachmentGroups,
      addGroupAndMove,
      isSelectMode,
      selectedRecords,
      setSelectedRecords,
      mode = '',
      expertViewJwt,
      requestId,
      refreshUrl,
      unidentifiedFilesToggle,
      setSessionOnly,
      refreshGroups,
      unidentifiedFilesPatient,
      unidentifiedUploadingFilesPatient,
      isUploading,
      canManageNotClassFiles,
      canAddHiddenFileToCase
   }) => {

    const collapsed = useToggle(false, (v) => uploaderUtils.isCollapsedRef.set(v ? _.union([group.id]) : _.difference(_, [group.id])))
    const editingName = useToggle(group.transient && group.name)

    const availableItems = group.items.filter((item) => flatAttachments.indexOf(item) >= 0)

    // const unidentifiedFilesPatient = uploaderUtils.getRecords().filter((item) => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item))
    // const unidentifiedUploadingFilesPatient = uploaderUtils.uploadingFiles.get().filter((item) => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item))
    // const isUploading = uploaderUtils.uploadingFiles.get().length > 0
    // const identifiedFiles = _.difference(uploaderUtils.getRecords(),unidentifiedFilesPatient)
    
    const updateItemKey = (attachment,newAttachment) => _.map(_.update('items', items => {
        const index = items.indexOf(getItemPersistedUID(attachment))
        if (index >= 0 && getItemPersistedUID(attachment) !== getItemPersistedUID(newAttachment)) {
          return [...items.slice(0, index), getItemPersistedUID(newAttachment), ...items.slice(index + 1)]
        } else {
          return items
        }
      })
    )

    const finishUpload = (instance) => response => {
      try {
        const uploadDate = Date.now()
        const setId = response.medicalRecordID ? _.set('recordUID', response.medicalRecordID) : _.identity
        const updater = _.flow(
          _.set(['mode'], mode ? mode : 'normal'),
          _.unset(['sourceFile']),
          _.unset(['fileToUpload']),
          _.set(['state'],Submitted),
          setId,
          _.set(['uploadDate'], uploadDate)
        )
        if (instance.isCompliant || instance.nonCompliant) {
          if (response.dicomAttributes) { // uuid -> SERVER NON COMPLIANT
            const updatedInstance = instanceFromServerAttributes(response.dicomAttributes, instance)
            const updater = () => setId(updatedInstance)
            if (instance.nonCompliant) {
              uploaderUtils.setRecords(removeNomCompliantInstance(instance))
              uploaderUtils.setRecords(_.flow(
                addDicomInstance(updatedInstance),
              ))
            }
            const study = uploaderUtils.getRecords().find(({studyUID}) => studyUID === instance.studyUID)

            if (study && (study.isNew || study.seriesUIDs.indexOf(instance.seriesUID) < 0)) {
              uploaderUtils.persistRecord(
                    _.flow(
                      _.set('uploadDate', uploadDate),
                      _.set('state',Submitted),
                      _.set(['mode'], mode ? mode : 'normal'),
                      setId,
                      _.update('seriesUIDs', _.union(instance.seriesUID ? [instance.seriesUID] : [])),
                      _.update('modalities', _.union(instance.modality ? [instance.modality] : []))
                    )(study)
              ).then(() => {
                uploaderUtils.setRecords((prevAttachments) => _.flow(
                  updateDicomInstance(instance, updater),
                  updateDicomStudy(instance, setId),
                  updateDicomStudy(instance, _.set('state',Submitted)),
                  _.unset([prevAttachments.findIndex(({studyUID}) => studyUID === instance.studyUID), 'isNew']),
                  _.set([prevAttachments.findIndex(({studyUID}) => studyUID === instance.studyUID), 'uploadDate'], Date.now()),
                  _.set([prevAttachments.findIndex(({studyUID}) => studyUID === instance.studyUID), 'mode'], mode ? mode : 'normal')
                  )(prevAttachments)
                )
                uploaderUtils.setAttachmentGroups(updateItemKey(instance, updater(instance)))
              })
            } else {
              uploaderUtils.setRecords(updateDicomInstance(instance, updater))
              uploaderUtils.setAttachmentGroups(updateItemKey(instance, updater(instance)))
            }
          } else {
            const attachmentUpdater = _.flow(
              updater,
              _.set('uid', response.uuid),
              setId
            )
            if (instance.isCompliant) {
              uploaderUtils.existingDicomInstances.set(_.difference(_, [instance.instanceUID]))
              uploaderUtils.setRecords(_.flow(
                removeDicomInstance(instance),
                addNonCompliantInstance(updater(instance), instance.sourceFile),
              ))
            }
            const attachment = instance.isCompliant ? getNonCompliantInstanceFile(instance, instance.sourceFile) : uploaderUtils.getRecords().find(a => a.sourceFile === instance.sourceFile)
            uploaderUtils.persistRecord(attachmentUpdater(attachment))
              .then(() => uploaderUtils.setRecords(updateNonCompliantInstanceSource(instance, attachmentUpdater)))
          }
        } else if (instance.multiFile) {
          uploaderUtils.setRecords(updateMultiFileInstance(instance, updater))
        } else {
          const attachmentUpdater = _.flow(
            updater,
            _.set('uid', response.uuid),
            setId
          )
          const attachment = uploaderUtils.getRecords().find(a => a.sourceFile === instance.sourceFile)
          const updatedAttachment = attachmentUpdater(attachment)
          uploaderUtils.persistRecord(updatedAttachment)
            .then(() => uploaderUtils.persistRecordInfo(updatedAttachment,createAttachment(attachment.sourceFile)))
            .then(() => {
              uploaderUtils.setRecords(updateFile(instance, attachmentUpdater))
              uploaderUtils.setAttachmentGroups(updateItemKey(attachment, attachmentUpdater(attachment)))
            })
        }
      } catch (e) {
        console.log("Completed upload exception",e,{instance,exception:e})
      } finally {
        updateActivityLogFile(instance.sourceFile,Uploaded,{recordUID: response.medicalRecordID,recordKey: instance.recordKey})
        uploaderUtils.uploadingFiles.set(_.differenceBy(_.property('sourceFile'), _, [instance]))
        uploaderUtils.startNextUpload()
      }

    }

    const onFailure = (instance) => (error) => {
      try {
        const update = _.flow(
          _.unset('fileToUpload'),
          error === Quarantined ? _.set('quarantined',true) : _.set('failed', error)
        )
        if (instance.instanceUID || instance.nonCompliant) {
          if (instance.isCompliant) {
            uploaderUtils.setRecords(updateDicomInstance(instance, update))
          } else {
            uploaderUtils.setRecords(updateNonCompliantInstance(instance, update))
          }
        } else if (instance.multiFile) {
          uploaderUtils.setRecords(updateMultiFileInstance(instance, update))
        } else {
          uploaderUtils.setRecords(updateFile(instance, update))
        }
      } catch (e) {
        console.log("Failure exception",e,{instance,error})
      } finally {
        uploaderUtils.uploadingFiles.set(_.differenceBy(_.property('sourceFile'), _, [instance]))
        if (error !== Quarantined) {
          uploaderUtils.failedFiles.set(_.concat([instance.sourceFile]))
        }
        updateActivityLogFile(instance.sourceFile,error)
        uploaderUtils.startNextUpload()
      }
    }

    const retryToQueue = (instance) => () => {
      const update = _.unset('failed')
      if (instance.instanceUID || instance.nonCompliant) {
        if (instance.isCompliant) {
          uploaderUtils.setRecords(updateDicomInstance(instance, update))
        } else {
          uploaderUtils.setRecords(updateNonCompliantInstance(instance, update))
        }
      } else if (instance.multiFile) {
        uploaderUtils.setRecords(updateMultiFileInstance(instance, update))
      } else {
        uploaderUtils.setRecords(updateFile(instance, update))
      }
      uploaderUtils.pendingFiles.set(_.concat([instance]))
      uploaderUtils.failedFiles.set(_.without([instance.sourceFile]))
      uploaderUtils.startNextUpload()
    }

    const onCancel = (instance) => () => {
      try {
        if (instance.instanceUID || instance.nonCompliant) {
          if (instance.isCompliant) {
            uploaderUtils.setRecords(removeDicomInstance(instance))
          } else {
            uploaderUtils.setRecords(removeNomCompliantInstance(instance))
          }
          uploaderUtils.existingDicomInstances.set(_.difference(_, [instance.instanceUID]))
        } else {
          uploaderUtils.setRecords(removeFile(instance,getItemSessionUID))
        }
      } catch (e) {
        console.log("Cancel exception",e,{instance})
      } finally {
        updateActivityLogFile(instance.sourceFile,Canceled)
        uploaderUtils.uploadingFiles.set(_.differenceBy(_.property('sourceFile'), _, [instance]))
        uploaderUtils.pendingFiles.set(_.differenceBy(_.property('sourceFile'), _, [instance]))
        uploaderUtils.failedFiles.set(_.without([instance.sourceFile]))
        uploaderUtils.startNextUpload()
      }

    }

    const removeStudyFromQueue = (study) => {
      const instanceUIDsToRemove = study.instances.filter(({sourceFile}) => sourceFile).map(({instanceUID}) => instanceUID)
      const sourceFilesToRemove = study.instances.map(({sourceFile}) => sourceFile).filter(_.identity)
      uploaderUtils.existingDicomInstances.set(_.difference(_, instanceUIDsToRemove))
      uploaderUtils.pendingFiles.set(_.filter(instance => instanceUIDsToRemove.indexOf(instance.instanceUID) < 0))
      uploaderUtils.failedFiles.set(_.filter(sourceFile => sourceFilesToRemove.indexOf(sourceFile) < 0))
      uploaderUtils.setRecords(removeUnploadedInstances(study))

      sourceFilesToRemove.map(sourceFile => updateActivityLogFile(sourceFile,Canceled))
    }

    const cancelMultiFile = (record) => {
      const sourceFilesToRemove = record.instances.map(({sourceFile}) => sourceFile).filter(_.identity)
      uploaderUtils.uploadingFiles.set(_.filter(instance => sourceFilesToRemove.indexOf(instance.sourceFile) < 0))
      uploaderUtils.pendingFiles.set(_.filter(instance => sourceFilesToRemove.indexOf(instance.sourceFile) < 0))
      uploaderUtils.failedFiles.set(_.filter(sourceFile => sourceFilesToRemove.indexOf(sourceFile) < 0))
      uploaderUtils.setRecords(removeRecord(record,getItemSessionUID))

      sourceFilesToRemove.map(sourceFile => updateActivityLogFile(sourceFile,Canceled))
      updateActivityLogBy('recordKey',record.key,Deleted)
    }

    const removeNonCompliantFromQueue = () => {
      const filesToRemove = records.filter(({sourceFile,format}) => format === NonCompliantDicom && sourceFile)
      const instanceUIDsToRemove = filesToRemove.map(_.get('instanceUID'))
      const sourceFilesToRemove = filesToRemove.map(_.get('sourceFile'))

      sourceFilesToRemove.map(sourceFile => updateActivityLogFile(sourceFile,Canceled))

      uploaderUtils.existingDicomInstances.set(_.difference(_, instanceUIDsToRemove))
      uploaderUtils.pendingFiles.set(_.filter(instance => sourceFilesToRemove.indexOf(instance.sourceFile) < 0))
      uploaderUtils.setRecords(removeUnploadedNonCompliant)
      uploaderUtils.failedFiles.set(_.filter(sourceFile => sourceFilesToRemove.indexOf(sourceFile) < 0))
    }

    const removeStudy = (study) => {
      uploaderUtils.existingDicomInstances.set(_.difference(_, study.instances.map(_.property('instanceUID'))))
      return uploaderUtils.removePersistedRecord(study.recordUID)
        .then(() => {
          let filtered = linkedStudies.filter(item => item != study.studyUID)
          setLinkedStudies(filtered)
          updateActivityLogBy('recordUID',study.recordUID,Deleted)
          return uploaderUtils.setRecords(removeDicomStudy(study))
        })
    }

    const removeNonDicomFile = (attachment) =>
      uploaderUtils.removePersistedRecord( attachment.recordUID)
        .then(() => {
          updateActivityLogBy('recordUID',attachment.recordUID,Deleted)
          return uploaderUtils.setRecords(removeFile(attachment, getItemSessionUID))
        })


    const removeMultiFile = (attachment) =>
      uploaderUtils.removePersistedRecord( attachment.recordUID)
        .then(() => {
          updateActivityLogBy('recordKey',attachment.key,Deleted)
          return uploaderUtils.setRecords(removeRecord(attachment, getItemSessionUID))
        })

    const finishMultiFile = (record,response) => {
      const uploadDate = Date.now()
      const setId = response.medicalRecordID ? _.set('recordUID', response.medicalRecordID) : _.identity
      const updater = _.flow(
        _.set(['uploadDate'], uploadDate),
        _.set(['state'], Submitted),
        setId
      )
      uploaderUtils.persistRecordInfo(updater(record))
      uploaderUtils.setRecords(updateRecord(record, updater, getItemSessionUID))
      uploaderUtils.setAttachmentGroups(updateItemKey(record, updater(record)))
    }

    const persistAttachmentInfo = (attachment) => (cb) => {
      const recordIndex = uploaderUtils.getRecords().findIndex(a => getItemSessionUID(a) === getItemSessionUID(attachment))
      const prevAttachment = uploaderUtils.getRecords()[recordIndex]
      const updateRecord = () => uploaderUtils.setRecords((attachments) => {
        const recordIndex = attachments.findIndex(a => getItemSessionUID(a) === getItemSessionUID(attachment))
        return _.update([recordIndex], cb, attachments)
      })
      const shouldUpdateBefore = attachment.state === cb(attachment).state
      if (shouldUpdateBefore) {
        updateRecord()
      }
      if (attachment.recordUID || attachment.id) {
        const promise = uploaderUtils.persistRecordInfo( cb(attachment), attachment )
        promise
          .then(() => { if (!shouldUpdateBefore) { updateRecord() } })
          .catch(() => uploaderUtils.setRecords(_.map(record => getItemSessionUID(record) === getItemSessionUID(attachment) ? prevAttachment : record)))
        return promise
      } else {
        return Promise.resolve()
      }
    }

    const removeNonCompliant = (allFiles) =>
      uploaderUtils.removePersistedRecord(ALL_NON_COMPLIANT_DICOM)
        .then(() => {
          allFiles.map((file) => updateActivityLogBy('recordUID',file.recordUID,Deleted))
          return uploaderUtils.setRecords(_.filter(({format}) => format !== NonCompliantDicom))

        })

    const removeNonCompliantFile = (nonCompliantFile) =>
      uploaderUtils.removePersistedRecord(nonCompliantFile.recordUID)
        .then(() => {
          updateActivityLogBy('recordUID',nonCompliantFile.recordUID,Deleted)
          return uploaderUtils.setRecords(removeNomCompliantInstance(nonCompliantFile))
        })


    const renderDraggableItem = (attachment, dragProvided, isDragging, index, isDragDisabled) => {
      if (attachment.key === NonCompliantDicom) {
        return <NonCompliantBox
          jwt={jwt}
          role={role}
          moveTo={(nextGroup) => moveRecordTo(index, {from:group.id,to:nextGroup})}
          updateState={uploaderUtils.updateMultipleRecordState}
          appJwt={appJwt}
          currentGroup={group.id}
          key="nonCompliant"
          isDragDisabled={isDragDisabled}
          disabled={disabled}
          downloadLoadUrl={apiEndpoints.loadDownloadRecord}
          downloadUrl={apiEndpoints.downloadFiles}
          withoutDelete={withoutDelete || listMode}
          removeAttachment={removeNonCompliant}
          removeNonCompliantFile={removeNonCompliantFile}
          attachments={records.filter(({format}) => format === NonCompliantDicom)}
          onFinishUpload={finishUpload}
          onCancel={onCancel}
          onFailure={onFailure}
          removeFromQueue={removeNonCompliantFromQueue}
          retry={retryToQueue}
          isApp={isApp}
          dragProvided={dragProvided}
          attachmentGroups={attachmentGroups}
          setAttachmentGroups={setAttachmentGroups}
          addGroupAndMove={addGroupAndMove}
          setNotification={setNotification}
          isSelectMode={isSelectMode}
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          mode={mode}
          expertViewJwt={expertViewJwt}
          requestId={requestId}
        />
      } else if (!attachment) {
        return null
      } else if (attachment.format === DicomStudy) {
        return <DicomBox
          jwt={jwt}
          role={role}
          moveTo={(nextGroup) => moveRecordTo(index, {from:group.id,to:nextGroup})}
          currentGroup={group.id}
          viewerLoadUrl={apiEndpoints.dicomViewer}
          downloadLoadUrl={apiEndpoints.loadDownloadRecord}
          downloadUrl={apiEndpoints.downloadFiles}
          appJwt={appJwt}
          disabled={disabled}
          isDragDisabled={isDragDisabled}
          withoutDelete={withoutDelete || listMode}
          key={getItemSessionUID(attachment)}
          noCancel={unprocessedFiles.length > 0}
          hasUnprocessedFiles={unprocessedFiles.length > 0}
          setSeriesList={uploaderUtils.setRecords}
          study={attachment}
          allowDownload={allowDownload}
          allowDownloadMedicalImages={allowDownloadMedicalImages}
          setAttachment={persistAttachmentInfo(attachment)}
          onFinishUpload={finishUpload}
          onCancel={onCancel}
          onFailure={onFailure}
          removeStudy={removeStudy}
          removeStudyFromQueue={removeStudyFromQueue}
          retry={retryToQueue}
          isApp={isApp}
          attachmentGroups={attachmentGroups}
          setAttachmentGroups={setAttachmentGroups}
          dragProvided={dragProvided}
          addGroupAndMove={addGroupAndMove}
          setNotification={setNotification}
          isSelectMode={isSelectMode}
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          mode={mode}
          expertViewJwt={expertViewJwt}
          requestId={requestId}
        />
      } if (attachment.recordClass === Pathology) {
        if (isMultiFile(attachment.format)) {
          return <PathologyMultiFilebox
            jwt={jwt}
            role={role}
            currentGroup={group.id}
            allowDownload={allowDownload}
            allowDownloadMedicalImages={allowDownloadMedicalImages}
            appJwt={appJwt}
            viewerUrl={apiEndpoints.nonDicomViewer}
            downloadLoadUrl={apiEndpoints.loadDownloadRecord}
            downloadUrl={apiEndpoints.downloadFiles}
            shortTokenUrl={apiEndpoints.shortTokenUrl}
            moveTo={(nextGroup) => moveRecordTo(index, {from:group.id,to:nextGroup})}
            disabled={disabled}
            isDragDisabled={isDragDisabled}
            withoutDelete={withoutDelete|| listMode}
            key={getItemSessionUID(attachment)}
            onFinishUpload={finishUpload}
            finishMultiFile={finishMultiFile}
            onCancel={() => cancelMultiFile(attachment)}
            onFailure={onFailure}
            retry={retryToQueue}
            setAttachment={persistAttachmentInfo(attachment)}
            setRecord={uploaderUtils.setRecords}
            removeRecord={removeMultiFile}
            record={attachment}
            isApp={isApp}
            attachmentGroups={attachmentGroups}
            setAttachmentGroups={setAttachmentGroups}
            dragProvided={dragProvided}
            addGroupAndMove={addGroupAndMove}
            setNotification={setNotification}
            isSelectMode={isSelectMode}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
            mode={mode}
            expertViewJwt={expertViewJwt}
            requestId={requestId}
          />
        }
        return <NonDicomBox
          jwt={jwt}
          role={role}
          appJwt={appJwt}
          moveTo={(nextGroup) => moveRecordTo(index, {from:group.id,to:nextGroup})}
          currentGroup={group.id}
          viewerUrl={apiEndpoints.nonDicomViewer}
          downloadUrl={apiEndpoints.downloadFiles}
          shortTokenUrl={apiEndpoints.shortTokenUrl}
          // downloadUrl={apiEndpoints.downloadRecord}
          pdfIframeSrc={apiEndpoints.pdfIframeSrc}
          disabled={disabled}
          isDragDisabled={isDragDisabled}
          withoutDelete={withoutDelete|| listMode}
          key={getItemSessionUID(attachment)}
          onFinishUpload={finishUpload(attachment)}
          onCancel={onCancel(attachment)}
          onFailure={onFailure(attachment)}
          retry={retryToQueue(attachment)}
          setAttachment={persistAttachmentInfo(attachment)}
          removeFile={() => removeNonDicomFile(attachment)}
          attachment={attachment}
          isApp={isApp}
          dragProvided={dragProvided}
          attachmentGroups={attachmentGroups}
          setAttachmentGroups={setAttachmentGroups}
          addGroupAndMove={addGroupAndMove}
          setNotification={setNotification}
          isSelectMode={isSelectMode}
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          mode={mode}
          expertViewJwt={expertViewJwt}
          requestId={requestId}
          records={records} 
          setRecords={setRecords} 
          refreshUrl={refreshUrl}
        />
      } else {
        return <NonDicomBox
          jwt={jwt}
          role={role}
          appJwt={appJwt}
          moveTo={(nextGroup) => moveRecordTo(index, {from:group.id,to:nextGroup})}
          currentGroup={group.id}
          viewerUrl={apiEndpoints.nonDicomViewer}
          downloadUrl={apiEndpoints.downloadFiles}
          shortTokenUrl={apiEndpoints.shortTokenUrl}
          // downloadUrl={apiEndpoints.downloadRecord}
          pdfIframeSrc={apiEndpoints.pdfIframeSrc}
          disabled={disabled}
          isDragDisabled={isDragDisabled}
          withoutDelete={withoutDelete|| listMode}
          key={getItemSessionUID(attachment)}
          onFinishUpload={finishUpload(attachment)}
          onCancel={onCancel(attachment)}
          onFailure={onFailure(attachment)}
          retry={retryToQueue(attachment)}
          setAttachment={persistAttachmentInfo(attachment)}
          removeFile={() => removeNonDicomFile(attachment)}
          attachment={attachment}
          isApp={isApp}
          dragProvided={dragProvided}
          attachmentGroups={attachmentGroups}
          setAttachmentGroups={setAttachmentGroups}
          addGroupAndMove={addGroupAndMove}
          setNotification={setNotification}
          isSelectMode={isSelectMode}
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          mode={mode}
          expertViewJwt={expertViewJwt}
          requestId={requestId}
          records={records} 
          setRecords={setRecords} 
          refreshUrl={refreshUrl}
        />
      }
    }


  const droppable = (snapshot,moveHandle) => 
    <Droppable
      droppableId={group.id}
      isCombineEnabled={collapsed.isOpen}
    >
      {(dropProvided, dropSnapshot) => <GroupContent>
        <GroupHeader
          editingName={editingName}
          canAddGroup={canAddGroup}
          groupActionButton={groupActionButton}
          flatAttachments={flatAttachments}
          snapshot={snapshot}
          collapsed={collapsed}
          group={group}
          allowReorder={allowReorder}
          moveHandle={moveHandle}
          setGroups={uploaderUtils.setAttachmentGroups}
          setNotification={setNotification}
          attachmentGroups={attachmentGroups}
          mode={mode}
          uploaderUtils={uploaderUtils}
          unidentifiedFilesToggle={unidentifiedFilesToggle}
          setSessionOnly={setSessionOnly}
          refreshGroups={refreshGroups}
          unidentifiedFilesPatient={unidentifiedFilesPatient}
          unidentifiedUploadingFilesPatient={unidentifiedUploadingFilesPatient}
          isUploading={isUploading}
          canManageNotClassFiles={canManageNotClassFiles}
          canAddHiddenFileToCase={canAddHiddenFileToCase}
        />
        <div
          css={isMinified(mode) ? "position:relative;margin-top:0rem;" :"position:relative;margin-top:-0.75rem;" }
          onMouseDown={
            (e) => 
              !document.activeElement?.closest(".JS-blur-container")?.contains(e.target) 
              // && document.activeElement.blur()
              && closeNotes()
            }

          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          {role !== EXPERT && role !== PROVIDER && group.id === 'notclass' && !collapsed.isOpen && mode !== DEVICE && mode !== DISK && group.items.length > 0 &&
            <>
              <AttachmentBox css="margin-bottom:1rem; background:white;" >
                <AttachmentIcon icon="files"/>
                <AttachmentInfoContainer>
                  <AttachmentDescription><b>{group.items.length} hidden files</b></AttachmentDescription>
                  <AttachmentDetails>These files do not appear to be medical records and have been hidden from the case. If you believe some may be relevant, you may review them and add them to the case.</AttachmentDetails>
                </AttachmentInfoContainer>
                <AttachmentActions>
                  <div className="buttons-wrapper">
                    {
                    <TextButton disabled={isUploading} 
                      onClick={() => { setSessionOnly(false); unidentifiedFilesToggle.open();}}
                    >

                      { isUploading ?
                        <><Loading size={16} borderWidth={3}/>Uploading</>
                      :
                        <>
                          <Icon icon="check-files"/>
                          Review hidden files
                        </>
                      }
                      </TextButton>
                    }
                  </div>
                </AttachmentActions>
              </AttachmentBox>
            </>
          }
          {/*<TransitionDiv>{
            !collapsed.isOpen &&
            // group.id !== 'notclass' &&
            availableItems.map((id, index) => {
              const attachment = id === NonCompliantDicom ? {key: NonCompliantDicom} : records.find(item => getItemPersistedUID(item) === id)
              const isDragDisabled = !allowReorder || (flatAttachments.length < 2 && groupsLength === 1)
              return <Draggable
                isDragDisabled={isDragDisabled}
                key={id} draggableId={id+""} index={index}
              >
                {(dragProvided, dragSnapshot) => renderDraggableItem( attachment, dragProvided, dragSnapshot.isDragging, index, isDragDisabled)}
              </Draggable>
            })
          }</TransitionDiv>*/}
          <div>
          </div>
          {dropProvided.placeholder}
        </div>
      </GroupContent>}
    </Droppable>


  if (!allowReorder && !availableItems.length) {
    return null
  }

  return <>
      <GroupContainer
        key={group.id}
        index={index}
        group={group}
        mode={mode}
        isNotClassMinified={group.id === 'notclass' && isMinified(mode)}
      >
        {
          group.id === 'ungrouped' || !canMoveGroup
            ?  <GroupWrapper emptyGroup={group.items.length === 0} mode={mode}>
                  {droppable()}
              </GroupWrapper>
            : <Draggable draggableId={group.id} index={index} isDragDisabled={!canMoveGroup || !allowReorder || group.id === 'notclass'}>
              {(provided, snapshot) =>
                <GroupWrapper ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging} 
                  // isNotClassMinified={group.id === 'notclass' && isMinified(mode)}
                >
                  {droppable(snapshot,canMoveGroup && allowReorder && <div {...provided.dragHandleProps}><DraggableIcon icon="draggable"/></div>)}
                </GroupWrapper>
              }
              </Draggable>
        }
      </GroupContainer>
    </>

  }

const GroupHeader = ({snapshot, editingName, collapsed, canAddGroup, groupActionButton, group, setGroups, moveHandle, flatAttachments, allowReorder, setNotification, attachmentGroups, mode, uploaderUtils, unidentifiedFilesToggle,  setSessionOnly, refreshGroups, unidentifiedFilesPatient, unidentifiedUploadingFilesPatient, isUploading}) => {
  return null
}
