import { lighten, darken,desaturate, transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, Loading, Button } from '@startlibs/components';
import {SimpleCheckbox} from '@startlibs/form'
import { getColor, media, customTheme } from '@startlibs/utils';
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';

// export const AttachmentBox = styled(({disabled, ...props}) => <Tooltip content={disabled ? 'not allowed' : ''} instant>
//   <div  {...props}/>
//   </Tooltip>
// )`

export const AttachmentBox = styled.div `
  padding: 1rem 1rem 1rem 54px;
  background: ${props => desaturate(0.65, lighten(0.545, getColor("main")(props)))};
  border-radius: 5px;
  margin-top: 0.75rem;
  border: 1px solid ${props => transparentize(0.75, darken(0.012, desaturate(0.4, getColor('main')(props))))};
  position: relative;
  min-height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  outline: 0;
  outline: none;
  cursor: default !important;
  ${props => props.isSelectMode && !props.disabledColor && css`
    cursor: pointer !important;
    :hover {
      background: ${props => desaturate(0.6, lighten(0.5, getColor("main")(props)))};
    }
    .link, .light-link {
      pointer-events: none;
    }
  `}
  ${props => props.disabledColor && css`
    opacity: 0.4 !important;
    .link, .light-link {
      pointer-events: none;
    }
    ${AttachmentCheckbox} {
      pointer-events: none;
    }
  `}
  ${props => props.unavailable && css`
    border: 1px solid rgba(0,0,0,0.15);
    background: ${getColor('gray240')};
    .link, .light-link {
      color: rgba(0,0,0,0.2);
      pointer-events: none;
    }
    ${TextButton} {
      background-color: rgba(0,0,0,0.1);
      color: ${getColor('gray120')};
      :hover {
        background-color: rgba(0,0,0,0.15);
      }
      :active {
        background-color: rgba(0,0,0,0.2);
      }
    }
  `}
  ${props => props.isSelected && css`
    background: ${props => desaturate(0.3, lighten(0.475, getColor("main")(props)))};
    border-color: ${getColor('main')};
    box-shadow: inset 0 0 0 1px ${getColor('main')};
    :hover {
      background: ${props => desaturate(0.3, lighten(0.45, getColor("main")(props)))};
    }
  `}
  ${props => props.failedUpload && css`
    background: ${props => lighten(0.51, props.theme.colors.alert)};
    border: 1px solid #e09f9f;
  `}
  ${props => props.hasFooter && css`
    padding-bottom: 4.5rem;
  `}
  ${props => props.hidden && css`
    display: none;
  `}
  ${media.max(520)`
    display: block;
    ${props => props.nonCompliantDeleteFileBox && css`
      min-height: 2rem;
    `}
    ${props => props.isApp && css`
      padding: 0.75rem 3rem 1rem 1rem;
      ${AttachmentIcon} {
        left: inherit;
        right: 1rem;
      }
    `}
  `}
  ${props => props.isLoading && css`
    opacity: 0.3;
  `}
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    padding: 8px 8px 8px 52px;
    background: ${props => desaturate(0.65, lighten(0.545, getColor("main")(props)))};
    border-radius: 0px; 
    margin-top: 0rem; 
    border: 0px solid;
    border-bottom: 1px solid lightgrey;
    position: relative;
    min-height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    outline: 0;
    outline: none;
    cursor: default !important;
  `}
  ${customTheme("AttachmentBox")};
`

export const AttachmentCheckbox = styled(SimpleCheckbox) `
  margin: 0.5rem -0.5rem 0 0;
  ${Icon} { 
    color: ${getColor('main')} !important;
  }
`

export const AttachmentInfoContainer = styled.div `
  flex-grow: 1;
  margin-right: 1.5rem;
  align-self: center;
`
export const AttachmentIcon = styled(Icon) `
  font-size: 36px;
  position: absolute;
  left: 0;
  top: 13px;
  width: 54px;
  text-align: center;
  ${props => props.icon === 'pathology' && css`
    font-size: 28px;
  `}
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    font-size: 28px !important;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
  `}
  ${customTheme("AttachmentIcon")};
`
export const AttachmentDescription = styled.div `
  font-size: 13px;
  width: 100%;
  .nowrap {
    flex-shrink: 0;
    margin-right: .5rem;
  }
  input {
    width: calc(100% - 90px);
    margin-top: -2px;
    height: 2rem;
    font-size: 13px;
    padding: 0 0.5rem;
  }
  .light-link, .link {
    font-size: 12px;
    white-space: nowrap;
  }
  ${media.max(520)`
    font-size: 12px;
    display: block;
    input {
      margin: 0.5rem 0;
      width: 100%;
    }
  `}
`
export const AttachmentDetails = styled.div `
  margin-top: 2px;
  .empty {
    color: rgba(0,0,0,0.3);
    font-style: italic;
  }
`
export const DropdownIcon = styled(Icon)`
`
export const AttachmentActions = styled.div `
  flex-shrink: 0;
  text-align: right;
  .buttons-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    order: 3;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  ${media.max(520)`
    justify-content: flex-end;
    margin: 1rem -0.25rem 0.25rem 0;
    padding-left: 2.25rem;
  `}
`

export const UploadedDate = styled.div`
  color: rgba(0,0,0,0.4);
  margin-top: 5px;
  margin-bottom: -0.5rem;
  font-size: 10px;
  display: block;
  text-align: left;
  float: right;
`

export const RejectLabel = styled.div `
  margin-top: 0.75rem;
  padding: 3px 3px 3px 10px;
  background: #f0d1d2;
  color: ${props => darken(0.1, getColor('alert')(props))};
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  margin-bottom: -2px;
  float: right;
  margin-left: 0.5rem;
  ${Button} {
    padding: 1px 0.5rem;
    margin-left: 0.5rem;
    min-height: 0;
    border-radius: 20px;
  }
`

export const TextButton = styled.a`
  text-decoration: none;
  color: ${getColor('main')};
  padding: 0.25rem 0.75rem;
  font-weight: 600;
  border-radius: 5px;
  user-select: none;
  position:relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
  flex-shrink: 0;
  cursor: pointer;
  background-color: ${props => transparentize(0.9, getColor('main')(props))};
  & ~ & {
    margin-left: 0.75rem;
  }
  :hover {
    background-color: ${props => transparentize(0.85, getColor('main')(props))};
  }
  :active {
    background-color: ${props => transparentize(0.8, getColor('main')(props))};
  }
  ${props => props.focused && css`
    background-color: ${props => transparentize(0.9, getColor('main')(props))};
    color: ${getColor('main')};
  `}
  ${props => props.isLoading && css`
    background-color: ${props => transparentize(0.9, getColor('main')(props))};
    color: ${getColor('main')};
    pointer-events: none;
  `}
  ${props => props.started && css`
    background-color: ${props => lighten(0.4, props.theme.colors.success)};
    color: ${getColor('success')};
    pointer-events: none;
  `}
  ${props => props.disabled && css`
    color: ${getColor('gray90')};
    pointer-events: none;
  `}
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: sub;
  }
  ${Icon} {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: bottom;
  }
  ${DropdownIcon} {
    font-size: 10px;
    margin-left: 0.25rem;
    margin-right: 0;
    transform: translateY(1px);
    vertical-align: baseline;
  }
  ${customTheme("AttachmentBoxButton")};
`
export const DropdownButton = styled(TextButton)`
  padding: 0.25rem;
  width: 2rem;
  text-align: center;
  ${TextButton} ~ & {
    margin-left: 0.75rem;
  }
  ${DropdownIcon} {
    font-size: 12px;
    margin-left: 0;
  }
`
export const UploadingStatus = styled.div `
  flex-shrink: 0;
  order: 3;
  ${media.max(520)`
    margin: -4px 0 0.5rem 0;
    padding-left: 2.5rem;
  `}
  .files-count {
    margin-top: 0.25rem;
  }
  .error {
    color: ${getColor('alert')};
    margin-right: 0.25rem;
    margin-left: 0;
  }
`

export const AttachmentBoxFooter = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1.25rem;
  min-height: 3.5rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  border-top: 1px solid ${props => transparentize(0.75, darken(0.012, desaturate(0.4, getColor('main')(props))))};
  ${props => props.alert && css`
    background: ${props => lighten(0.51, props.theme.colors.alert)};
    border-top: 1px solid rgba(0,0,0,0.15);
    color: ${getColor('alert')};
  `}
  ${Button} {
    min-width: 6rem;
    & ~ ${Button} {
      margin-left: 0.75rem !important;
    }
  }
`

const Progress = styled.div`
  width: ${props => props.progress || 0}%;
  height: 100%;
  min-width: 4px;
  display: block;
  border-radius: 30px;
  background-color: ${getColor('main')};
  transition: 0.25s linear;
  ${props => props.completed && css`
    background-color: ${getColor('success')};
    width: 100%;
  `}
  ${props => props.failed && css`
    background-color: ${getColor('alert')};
  `}
`

export const FileNotFoundErrorBox = styled.div`
  padding: 0.75rem 1rem;
  min-height: 3rem;
  background: ${props => lighten(0.51, props.theme.colors.alert)};
  border: 1px solid #e09f9f;
  color: #811115;
  border-radius: 5px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .link {
    white-space: nowrap;
    margin-left: 1rem;
  }
`

export const ProgressBar = styled(({className,failed,completed,progress}) =>
  <div className={className}><Progress failed={failed} completed={completed} style={{width: (completed ? 100 : (progress || "0"))+"%"}}/></div>
)`
  height: 8px;
  width: 100%;
  border-radius: 30px;
  background-color: rgba(0,0,0,0.075);
  overflow: hidden;
  margin-top: 4px;
  user-select: none;
`


export const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  ${props => props.failed && css`
    color: ${getColor('alert')};
    display: block;
    ${media.min(521)`
      text-align: right;
    `}
  `}
  a {
    margin-left: 0.25rem;
  }
`

export const DraggableCornerIcon = styled(Icon)`
  position: absolute;
  padding: 0.25rem 1.25rem 1.25rem 0.25rem;
  border-bottom-right-radius: 36px;
  top: 0;
  left: 0;
  font-size: 16px;
  color: rgba(0,0,0,0.3);
  cursor: grab;
  z-index: 10;
  :hover {
    color: rgba(0,0,0,0.5);
  }
`

export const AttachmentBoxFlexContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const UploadingRecords = styled.span`
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  ${Loading} {
    margin-right: 0.5rem;
  }
`