import {Icon} from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import {lighten, desaturate, transparentize, darken} from 'polished';
import styled, {css} from 'styled-components';

export const InfoBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
  color: ${getColor('gray120')};
  ${props => props.flex && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
  ${props => props.lightBlue && css`
    background: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))};
    border: 1px solid;
    border-color: ${props => transparentize(0.93, darken(0.012, desaturate(0.4, getColor('main')(props))))};
    color: ${getColor('main')};
  `}
  ${props => props.lightYellow && css`
    background: ${getColor('lightYellow')};
    border: 1px solid ${getColor('warning')};
    color: ${getColor('warning')};
  `}
  ${props => props.dark && css`
    background: ${getColor('gray150')};
    border-color: ${getColor('gray150')};
    color: white;
  `}
  ${props => props.error && css`
    background: ${props => lighten(0.43, props.theme.colors.alert)};
    border-color: ${props => lighten(0.32, props.theme.colors.alert)};
    color: ${getColor('alert')};
  `}
  ${props => props.withIcon && css`
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 16px;
      margin-right: .5rem;
      font-weight: 400;
    }
  `}
  ${props => props.autoSaveNotice && css`
    margin: 0 0 2rem;
  `}
  ${props => props.noDivisionsNotice && css`
    position: absolute;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    z-index: 120;
    ${Icon} {
      color: ${getColor('lightYellow')};
      display: inline-block;
      background-color: ${getColor('warning')};
      font-size: 14px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;
      &:hover {
        opacity: 0.75;
      }
    }
  `}
  > span {
    font-weight: 600;
  }
  .patient-name, .contact-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .25rem;
  }
  .diagnosis {
    margin-bottom: .25rem;
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    > div {
      &:nth-child(1) {
        margin-right: 1rem;
        flex-grow: 1;
      }
      &:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
`
export const SuccessBox = styled.div`
  background: ${getColor('lightGreen')};
  color: ${getColor('success')};
  padding: .75rem;
  border-radius: 5px;
  position: relative;
  width: auto;
  font-weight: 400;
  font-style: italic;
  display: inline-flex;
  align-items: center;
  ${props => props.topRight && `
    position: absolute;
    top: .5rem;
    right: .5rem;
  `}
  ${Icon} {
    font-size: 13px;
    line-height: 15px;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: 2px solid ${getColor('success')};
    margin-right: .5rem;
    padding-left: 2px;
  }
`
