import {Icon} from '@startlibs/components';
import {formatDate, getColor, media} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {DATE_FORMAT} from '../../../config/DateFormat'
import {addZeroPrefix, getDateValue} from '../../../utils/utils'
import {lazyEvents} from './utils'
import {WithLazyResource} from '../../../hooks/useSuspense'
import { desaturate, lighten, transparentize } from 'polished';


const ActiveFilters = styled.div `
  margin-bottom: -1.25rem;
  ${media.max(800) `
    margin-bottom: -0.75rem;
  `}
  ${media.max(625) `
    margin-bottom: 0;
  `}
  margin-top: 0.5rem;
  flex-basis: 100%;
  > span {
    margin-top: 0.25rem;
    background: rgba(0,0,0,0.075);
    border-radius: 50px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
    line-height: 2rem;
    display: inline-block;
    &.past-year {
      background: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))};
      border: 1px solid ${getColor('main')};
      color: ${getColor('main')};
      :hover {
        background: rgba(185,200,210,0.3);
      }
      ${Icon} {
        background: ${props => transparentize(0.875, getColor('main')(props))};
        :hover {
          background: #dec0c1;
          color: ${getColor('alert')};
        }
      }
    }
  }
  > span:hover {
    background: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  ${props => props.popupIsOpen && `
    opacity: 0.4;
    pointer-events: none;
  `}
  ${Icon} {
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 -0.25rem 0 0.25rem;
    text-align: center;
    display: inline-block;
    vertical-align: -1px;
    :hover {
      background: #dec0c1;
      color: ${getColor('alert')};
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .tag {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: -8px;
  }
`

const getTimeLabel = (value) => {
  if (!value || value === '-') return '';
  const [hour,minutes] = value.split("-")
  return hour+ ":" + addZeroPrefix(minutes || "0")
}
export const ActiveAdvancedFilters = ({advancedPopup,params,setParams}) => {

  const from = getDateValue('from',params)
  const to = getDateValue('to',params)
  const timeFrom = getTimeLabel(params.timeFrom)
  const timeTo = getTimeLabel(params.timeTo)
  const uploadDateSameDay = from && to && from.getTime() === to.getTime() && !timeFrom && !timeTo

  const hasFilters = from || to || params.events

  const removeKeys = (...keys) => (e) => {
    keys.forEach(key => {
      setParams(_.unset(key))
    })
    e.stopPropagation()
  }
  const removeEvent = (event) => (e) => {
    setParams(_.update('events',(events) => _.without([event],events.split("|")).join("|")))
    e.stopPropagation()
  }

  if (!hasFilters) return null


  return <ActiveFilters popupIsOpen={advancedPopup.isOpen}>
    <b>Filtering:</b>
    {
      !uploadDateSameDay && !!from && !!to && <span onClick={advancedPopup.willOpenWith("from")}>Events between: {formatDate(from,DATE_FORMAT())} {timeFrom} and {formatDate(to,DATE_FORMAT())} {timeTo}<a onClick={removeKeys('from','to','timeFrom',"timeTo")}><Icon icon="x"/></a></span>
    }
    {
      !!uploadDateSameDay && <span onClick={advancedPopup.willOpenWith("from")}>Events on: {formatDate(from,DATE_FORMAT())} <a onClick={removeKeys('from', 'to','timeFrom','timeTo')}><Icon icon="x"/></a></span>
    }
    {
      !to && !!from && <span onClick={advancedPopup.willOpenWith("from")}>Events since: {formatDate(from,DATE_FORMAT())} {timeFrom} <a onClick={removeKeys('from','timeFrom')}><Icon icon="x"/></a></span>
    }
    {
      !!to && !from &&
      <span onClick={advancedPopup.willOpenWith("to")}>Events up to: {formatDate(to,DATE_FORMAT())} {timeTo} <a onClick={removeKeys('to','timeTo')}><Icon icon="x"/></a></span>
    }
    <WithLazyResource value={lazyEvents}>{ events =>
      (params.events||"").split("|")
        .map(eventKey => events.find(({id})=>id===eventKey))
        .filter(Boolean)
        .map(({id,name}) =>
          <span onClick={advancedPopup.willOpenWith("events")}>{name}<a onClick={removeEvent(id)}><Icon icon="x"/></a></span>
        )
    }</WithLazyResource>
  </ActiveFilters>
}
