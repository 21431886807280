import { Button, Icon, Loading, Tooltip, TextButton, TransitionDiv} from '@startlibs/components';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {Header} from '../../components/Header'
import {Card, PageContainer} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import { getColor, will } from '@startlibs/utils';
import { PENDING_REQUEST, WAITING_ACCEPTANCE, UNDER_REVIEW, CASE_REVIEWED, CASE_CLOSED, WAITING_MEDICAL_RECORDS, WAITING_MORE_INFORMATION, WAITING_APPROVAL, PENDING_ASSIGNMENT, CASE_ARCHIVED, CASE_DRAFT } from '../../enums/CaseState';
import { formatDateNoUTC } from '../../utils/utils';
import { willUseSuspense } from '../../hooks/useSuspense';
import { jwtGetFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { isNotRevoked } from './expertReview/utils';
import { isStateBefore } from '../../request/utils';
import { lazyTerms } from '../../patient/utils/caseRequestUtils';
import { useToggle } from '@startlibs/core';
import { CaseDashboardCard } from '../../components/CaseDashboardCard';
import { useNavigate } from 'react-router';
import { darken, desaturate, lighten } from 'polished';
import { WaitingMedicalRecordsBox } from './info/WaitingMedicalRecordsBox';
import { isPendingAskingMore } from '../../patient/utils/patientUtils';
import { useUnarchiveDialog } from '../hooks/useUnarchiveDialog';
import { has } from 'lodash';
import { DetailContainer } from '../controlPanel/audit/Table';
import { useIntl } from 'react-intl';
import { shouldShowLogDetails } from '../controlPanel/audit/AuditRow';
import { IfLocale, useIsLocale } from '../../hocs/IfLocale';
import { lazyProviderInfo } from '../../components/WithProvider';

const ActivityCard = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  ${Icon} {
    font-size: 24px;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    margin-right: 1.5rem;
    color: black;
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    position: relative;
  }
  ${Button} {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  ${TextButton} {
    margin-left: auto;
    margin-right: 0.5rem;
    font-weight: bold;
  }
  .activityDetail {
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    margin-top: 2px;
  }
  & + & {
    margin-top: 3rem;
    ${Icon} {
      :after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-100%);
        height: 3.25rem;
        width: 2px;
        background: ${getColor('gray240')};
      }
    }
  }
  ${props => props.box && css`
    border-radius: 8px;
    padding: 1rem;
    background: ${getColor('gray240')};
    ${Icon} {
      margin-right: 0.75rem;
    }
  `}
  ${props => props.box && props.pendingActivity && css`
    background: ${getColor('lightYellow')};
  `}
  ${props => props.margintop && css`
    margin-top: 2rem;
  `}
`

const PageTitle = styled.div`
  font-size: 20px;
  color: ${getColor('main')};
  font-weight: 600;
`
const CaseId = styled.div`
  font-size: 14px;
  color: ${getColor('gray90')};
  margin-top: 2px;
` 
const getIconByEventTypeName = (eventTypeName) => {
  switch (eventTypeName) {
    case "Case Accessed":
      return "view"
    case "Case Approval Requested":
      return "email-line"
    case "Case Approved":
      return "check"
    case "Sent reminder to Case Contact":
      return "clock"
    case "Read Only Case Share Revoked from Expert":
      return "physician-line"
    case "Case Declined":
      return "x-circle"
    case "Case Division Added":
      return "plus-circle"
    case "Case Division Removed":
      return "x-circle"
    case "Records Requested":
      return "dicom-no-text"
    case "Case Rejectd To Draft":
      return "x-circle"
    case "Case Reviewed":
      return "checked-report"
    case "Case Review Accepted":
      return "check"
    case "Case Declined by Expert":
      return "physician-line"
    case "Case Unassigned from Expert":
      return "physician-line"
    case "Case Review Completed":
      return "checked-report"
    case "Case Review Update Request":
      return "edit"
    case "Read Only Case Shared to Expert":
      return "physician-line"
    case "Case Assigned to Expert":
      return "physician-line"
    case "Case Updated":
      return "edit"
    case "Case Update Requested":
      return "email-line"
    case "Sent reminder to Expert":
      return "physician-line"
    case "External Report Uploaded":
      return "checked-report"
    case "Fee Added":
      return "payment"
    case "Payment Made":
      return "payment"
    case "Payment Requested":
      return "payment"
    case "Release Forms Requested":
      return "releases"
    case "Request Accepted":
      return "check"
    case "Case Created":
      return "plus-circle"
    case "Patient Information Requested":
      return "email-line";
    case "Video Consult Joined":
      return "video"
    case "Video Consult Link Sent":
      return "video"
    case "Patient Login":
      return "arrow-right-line"
    case "Patient Logout":
      return "arrow-left-line"
    default:
      return "check-files"
  }
}

const useCurrentExpertsSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/experts/bycase/${requestId}`)
)

const useLastAuditEventSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/case/lastAuditEvent?requestId=${requestId}`)
)

const useAuditEventsSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/case/auditEvents?requestId=${requestId}`)
)


const CheckPoint = styled.div`
  background: ${getColor('gray240')};
  width: 1.5rem;
  height: 1.5rem;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  position: relative;
  ${props => props.checked && css`
    background: ${props => props.timelineCheckColor ? props.timelineCheckColor : getColor('main')};
    color: white;
    box-shadow: 0 0 0 4px ${props => props.customColor ? props.customColor :  desaturate(0.5, lighten(0.5, getColor("main")(props)))};
  `}
  ${props => props.isArchived && css`
    background: ${getColor('gray210')};
    box-shadow: 0 0 0 4px ${getColor("gray240")};
  `}
  ${props => props.skipped && css`
    background: ${props => props.customColor ? darken(0.2, props.customColor) : desaturate(0.5, lighten(0.4, getColor("main")(props)))};
    box-shadow: 0 0 0 4px ${props =>  props.customColor ? props.customColor : desaturate(0.5, lighten(0.5, getColor("main")(props)))};
  `}

`
// ${props => props.isCompleted ? css`
//     ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))}
//   ` : props.wasAssigned ? css`
//     linear-gradient(to right, ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))} 67%, white 67% 100%)
//   ` : props.wasAccepted ? css`
//     linear-gradient(to right, ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))} 34%, white 34% 100%)  
//   ` : `white`};
const Timeline = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-radius: 100px;
  position: relative;
  background:
  ${props => props.isCompleted ? css`
    ${props => props.customColor ? props.customColor : desaturate(0.5, lighten(0.5, getColor("main")(props)))}
  ` : props.wasReviewed ? css`
    linear-gradient(to right, ${props => props.isArchived ? getColor('gray240') : props.customColor ? props.customColor : desaturate(0.5, lighten(0.5, getColor("main")(props)))} 75%, white 75% 100%)
  ` : props.wasAssigned ? css`
    linear-gradient(to right, ${props => props.isArchived ? getColor('gray240') : props.customColor ? props.customColor : desaturate(0.5, lighten(0.5, getColor("main")(props)))} 50%, white 50% 100%)
  ` : props.wasAccepted ? css`
    linear-gradient(to right, ${props => props.isArchived ? getColor('gray240') : props.customColor ? props.customColor : desaturate(0.5, lighten(0.5, getColor("main")(props)))} 25%, white 25% 100%)
  ` : `white`};
  padding: 4px;
  margin-bottom: 6rem;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.08);
    pointer-events: none;
  }
`
const CheckpointLabel = styled.div`
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 2.5rem;
  color: ${getColor('gray60')};
  font-size: 14px;
  ${props => !props.left && !props.right && `
    left: 50%;
    transform: translateX(-50%);
  `}
  ${props => props.left && `
    left: 0;
    text-align: left;
  `}
  ${props => props.right && `
    right: 0;
    text-align: right;
  `}
`

const CaseDashboardCardContainer = styled.div `
  display: flex;
  margin-bottom: 2rem;
`

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  ${Icon} {
    font-size: 20px;
  }
`

export const StatusStep = ({caseRequest, setCaseRequest}) => {

  const intl = useIntl()
  const isLegal = useIsLocale({contains:'LEGAL'})

  const paidRequests = caseRequest.payments.filter(req => req.paid == true).length
  const paymentRequests = caseRequest.payments.filter(req => req.revoked == false || req.revoked == undefined || req.revoked == null).length
  
  const showDetails = useToggle()
  const showArchivedDetails = useToggle()
  const [auditEventsDetailsToggle, setAuditEventsDetailsToggle] = useState([])

  const navigate = useNavigate()
  const viewHistory = useToggle()
  
  const providerInfo = lazyProviderInfo.read()

  const terms = providerInfo?.serviceTerms === true ? lazyTerms.read(getJwt()) : []
  const termsWithAgreement = terms
    .map(term => caseRequest.acceptanceInfo?.agreements.find(agreement => agreement.serviceTermId === term.id)).filter(Boolean)   
  
  const [rawSharedExperts, setSharedExperts] = useState(useCurrentExpertsSuspense(caseRequest.requestId))

  
  const reviewedExperts = rawSharedExperts.filter(isNotRevoked(caseRequest))
  // order the experts by the most recent review date
  const sharedExperts = reviewedExperts.sort((a, b) => {
    return b.lastUpdatedEpochMilli - a.lastUpdatedEpochMilli
    // return b.reviewDateEpochMilli - a.reviewDateEpochMilli
  })
  
  const reviewedByExpert = sharedExperts && sharedExperts.length > 0 && sharedExperts[0].reviewDateEpochMilli ? true : false;
  const reviewedDate = sharedExperts && sharedExperts.length > 0 && sharedExperts[0].reviewDateEpochMilli ? sharedExperts[0].reviewDateEpochMilli : null;
  const expertName = sharedExperts && sharedExperts.length > 0 && sharedExperts[0]?.expert?.firstName !== '' 
    ? sharedExperts[0].expert.firstName + ' '+ sharedExperts[0].expert.lastName 
    : sharedExperts && sharedExperts.length > 0 
      ? sharedExperts[0]?.expert?.email 
      : '';

  // const [auditEvents, setAuditEvents] = useState([])
  const [auditEvents, setAuditEvents] = useState(useAuditEventsSuspense(caseRequest.requestId))
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [lastAuditEvent, setLastAuditEvent] = useState(useLastAuditEventSuspense(caseRequest.requestId))
  const [lastDeclineEvent, setLastDeclineEvent] = useState()
  const [lastAssignDate, setLastAssignDate] = useState()
  const [lastReviewDate, setLastReviewDate] = useState()
  const [lastCompleteDate, setLastCompleteDate] = useState()
  const [experts, setExperts] = useState([])
  const [admins, setAdmins] = useState([])
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report

  const unarchive = useUnarchiveDialog(caseRequest,_.flow(
    will(setCaseRequest,_.set("state", caseRequest?.whenAcceptedEpochMilli ? CASE_DRAFT : PENDING_REQUEST )),
    // will(navigate,`/admin/request/${caseRequest.requestCode}`)
  ),false)

  useEffect(() => {
    if(experts.length === 0){
      jwtGetFetcher(getJwt())(`/api/experts?nocache=${new Date().getTime()}`)
      .then((response) => {
        setExperts(response)
      })
    }
    if(admins.length === 0){
      jwtGetFetcher(getJwt())(`/api/admin/searchUsers?nocache=${new Date().getTime()}`)
      .then((response) => {
        setAdmins(response.list)
      })
    }
  },[])
  
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  },[viewHistory.isOpen])

  useEffect(() => {
    
    // if(auditEvents.length === 0){
      if(auditEvents.length > 0){
      // jwtGetFetcher(getJwt())(`/api/case/auditEvents?requestId=${caseRequest.requestId}`)
      // .then((response) => {
        // setAuditEvents(response)
        setLoadingEvents(false)

        // Get last Assignment Events from Audit Events
        let lastAssignEvent = auditEvents.filter(event => event.event === "Case Assigned to Expert").sort((a, b) => b.createdEpochMili - a.createdEpochMili)[0]
        if(lastAssignEvent){
          setLastAssignDate(lastAssignEvent.createdEpochMili)
        }else{
          setLastAssignDate(caseRequest.whenSubmittedEpochMilli)
        }
        
        // Get last Review Events from Audit Events
        let lastReviewEvent = auditEvents.filter(event => event.event === "Case Reviewed" || event.event === "Case Set as Reviewed").sort((a, b) => b.createdEpochMili - a.createdEpochMili)[0]
        if(lastReviewEvent){
          setLastReviewDate(lastReviewEvent.createdEpochMili)
        }else{
          setLastReviewDate(reviewedDate)
        }

        // Get last Complete Events from Audit Events
        let lastCompleteEvent = auditEvents.filter(event => event.event === "Case Review Completed").sort((a, b) => b.createdEpochMili - a.createdEpochMili)[0]
        if(lastCompleteEvent){
          setLastCompleteDate(lastCompleteEvent.createdEpochMili)
        }else{
          setLastCompleteDate(caseRequest.whenFinishedEpochMilli)
        }

        if(isArchived){
          let lastDeclineEvent = auditEvents.filter(event => event.event === "Case Declined").sort((a, b) => b.createdEpochMili - a.createdEpochMili)[0]
          setLastDeclineEvent(lastDeclineEvent)
        }
      }
    
  },[auditEvents])

  const wasCreated = true;
  const isArchived = caseRequest.state === CASE_ARCHIVED;

  // const wasAccepted = caseRequest.state !== PENDING_REQUEST && caseRequest.state !== CASE_ARCHIVED;
  const wasAccepted = caseRequest.whenAcceptedEpochMilli !== null || (caseRequest.state !== PENDING_REQUEST && caseRequest.state !== CASE_ARCHIVED);
  // Sometimes, the dateWhenAccepted is null and the case is already in case draft, so we will use the date when created
  const dateWhenAccepted = caseRequest.whenAcceptedEpochMilli !== null 
    ? caseRequest.whenAcceptedEpochMilli 
    : caseRequest.state !== PENDING_REQUEST 
      ? caseRequest.whenCreatedEpochMilli 
      : null;

  
  const wasAssigned = ((caseRequest.state === WAITING_ACCEPTANCE 
  || caseRequest.state === UNDER_REVIEW 
  || caseRequest.state === CASE_REVIEWED 
  || caseRequest.state === CASE_CLOSED
  || caseRequest.state === WAITING_MORE_INFORMATION) && lastAssignDate !== null)
  // || caseRequest.state === CASE_ARCHIVED
  // || caseRequest.state === REQUEST_REJECTED;
  const wasReviewed = (caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) && lastReviewDate !== null;
  const isCompleted = caseRequest.state === CASE_CLOSED && lastCompleteDate !== null;

  const readOnly = [WAITING_MEDICAL_RECORDS, UNDER_REVIEW, WAITING_MORE_INFORMATION, WAITING_APPROVAL, WAITING_ACCEPTANCE, CASE_CLOSED].indexOf(caseRequest.state) >= 0
  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)

  const findUserByEventAndMail = (event, email) => {
    return event.indexOf("Patient") >= 0 
      ? email 
      : admins.find(adm => adm.email === email) 
        ? admins.find(adm => adm.email === email).firstName + ' ' + admins.find(adm => adm.email === email)?.lastName + ' ('+email+')'
        : experts.find(exp => exp.expertEmail === email) 
          ? experts.find(exp => exp.expertEmail === email).firstName + ' ' + experts.find(exp => exp.expertEmail === email)?.lastName + ' ('+email+')'
          : email === 'net.purview.security.ReadOnlyAccessPrincipal' 
            ? 'Read Only Access'
            : email
  }

  return <>
    <PageContainer>
      <Header title="Case status and activity"></Header>
      {!viewHistory.isOpen && <Card>

        {/* Header */}
        <PageTitle className="fs-exclude">{(caseRequest.firstName || caseRequest.patientInfo?.firstName) 
        + ' ' + ((caseRequest.middleName ? caseRequest.middleName + ' ' : '') || (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName + ' ' : '')) 
        + (caseRequest.lastName || caseRequest.patientInfo?.lastName)}</PageTitle>
        {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>}
        
        {/* Archived Card */}
        {isArchived && lastDeclineEvent && <ActivityCard box margintop>
          <Icon icon={"x-circle"} />
          <div css="margin-right:1rem;">  
            <div><b>This case is currently archived </b>
              {shouldShowLogDetails(lastDeclineEvent)
                // !(lastDeclineEvent?.info?.message?.length == 0 && Object.keys(lastDeclineEvent?.info).length == 1 && lastDeclineEvent?.info?.message) 
                // && (lastDeclineEvent?.message?.length > 0 || Object.keys(lastDeclineEvent?.info).length > 0 ) 
                ? <a className="link" onClick={showArchivedDetails.toggle}>Details</a> 
                : null
              }
            </div>
            <TransitionDiv>
              {showArchivedDetails.isOpen && lastDeclineEvent?.info?.reason?.length > 0 && <DetailContainer><strong>Reason: </strong>{lastDeclineEvent?.info?.reason}</DetailContainer>}
              {showArchivedDetails.isOpen && lastDeclineEvent?.info?.REASON?.length > 0 && <DetailContainer><strong>Reason: </strong>{lastDeclineEvent?.info?.REASON}</DetailContainer>}
              {showArchivedDetails.isOpen && lastDeclineEvent?.message?.length > 0 && <DetailContainer><strong>Message: </strong>{lastDeclineEvent?.message}</DetailContainer>}
            </TransitionDiv>
            {lastDeclineEvent && <div className='activityDetail'>{formatDateNoUTC(new Date(lastDeclineEvent?.createdEpochMili), "MM/dd/yyyy at hh:mm")} by {findUserByEventAndMail(lastDeclineEvent?.event,lastDeclineEvent?.who)}</div>}
          </div>
          <Button small highlight onClick={unarchive}>Unarchive</Button>
          {/* <TextButton onClick={() => viewHistory.toggle()}>View history</TextButton> */}
        </ActivityCard>}

        {/* Timeline with dates and actions */}
        <Timeline isArchived={isArchived} wasReviewed={wasReviewed} wasAccepted={wasAccepted} wasAssigned={wasAssigned} isCompleted={isCompleted}
          customColor={providerInfo?.customColors?.timeline}
        >
          <Tooltip content={formatDateNoUTC(new Date(caseRequest.whenCreatedEpochMilli), "MM/dd/yyyy at hh:mm")}>
            <CheckPoint checked={wasCreated} isArchived={isArchived} 
              customColor={providerInfo?.customColors?.timeline} 
              timelineCheckColor={providerInfo?.customColors?.timelineCheck}
            >
              {wasCreated && <Icon icon="check"/>}
              <CheckpointLabel left>
                  <b>Requested</b>
                  <p>{formatDateNoUTC(new Date(caseRequest.whenCreatedEpochMilli), "MM/dd/yyyy")}</p>
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={wasAccepted ? formatDateNoUTC(new Date(dateWhenAccepted), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={wasAccepted} isArchived={wasAccepted && isArchived} 
              customColor={providerInfo?.customColors?.timeline}
              timelineCheckColor={providerInfo?.customColors?.timelineCheck}
            >
              {wasAccepted && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: wasAccepted ? 1 : '0.3' }}>
                  <b>Accepted</b>
                  {wasAccepted && <p>{formatDateNoUTC(new Date(dateWhenAccepted), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          {/* <Tooltip content={wasAssigned ? formatDateNoUTC(new Date(caseRequest.whenSubmittedEpochMilli), "MM/dd/yyyy at hh:mm") : null}> */}
          <Tooltip content={(lastAssignDate) ? formatDateNoUTC(new Date(lastAssignDate), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={wasAssigned} skipped={!wasAssigned && wasReviewed} 
              customColor={providerInfo?.customColors?.timeline}
              timelineCheckColor={providerInfo?.customColors?.timelineCheck}
            >
              {wasAssigned && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: wasAssigned ? 1 : '0.3' }}>
                  <b>Assigned</b>
                  {(lastAssignDate) ? <p>{formatDateNoUTC(new Date(lastAssignDate), "MM/dd/yyyy")}</p> : wasReviewed ? <p>Skipped</p> : null}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={lastReviewDate ? formatDateNoUTC(new Date(lastReviewDate), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={wasReviewed} 
              customColor={providerInfo?.customColors?.timeline} 
              timelineCheckColor={providerInfo?.customColors?.timelineCheck}
            >
              {wasReviewed && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: wasReviewed ? 1 : '0.3' }}>
                  <b>Reviewed</b>
                  {lastReviewDate && <p>{formatDateNoUTC(new Date(lastReviewDate), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={lastCompleteDate ? formatDateNoUTC(new Date(lastCompleteDate), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={isCompleted} 
              customColor={providerInfo?.customColors?.timeline}
              timelineCheckColor={providerInfo?.customColors?.timelineCheck}
            >
              {isCompleted && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: isCompleted ? 1 : '0.3'}} right>
                  <b>Completed</b>
                  {lastCompleteDate && <p>{formatDateNoUTC(new Date(lastCompleteDate), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
        </Timeline>
        
        {/* Cards with numbers */}
        <CaseDashboardCardContainer>
          <IfLocale not contains="LEGAL">
            <CaseDashboardCard 
              mainNumber={termsWithAgreement.length+" of "+terms.length} 
              footerText={"Agreements signed"} 
              success={termsWithAgreement.length - terms.length === 0} 
              title="Check agreements"
              path={"agreements"}
            />
          </IfLocale>
          <IfLocale not contains="LEGAL">
            <CaseDashboardCard 
              disabled={false} 
              isLoading={false} 
              mainNumber={paymentRequests > 0 ? paidRequests+" of "+paymentRequests : null}
              headerText={paymentRequests == 0 ? "Payment" : null} 
              mainText={paymentRequests == 0 ? "No fee added" : null} 
              footerText={paymentRequests > 0 ? "Fees paid" : null} 
              success={paymentRequests === paidRequests && paymentRequests > 0}
              title="Check payments" 
              path={"payment"}
            />
          </IfLocale>
          <CaseDashboardCard 
            headerText={"Assigned to:"} 
            mainText={wasAssigned ? expertName : caseRequest.state === PENDING_ASSIGNMENT ? "Assign now": "Not provided"} 
            success={wasAssigned} 
            disabled={!wasAssigned && caseRequest.state !== PENDING_ASSIGNMENT}
            title="View experts" 
            path={"expert"}
          />
          <CaseDashboardCard 
            headerText={"Report"} 
            mainText={reviewedByExpert ? isCompleted ? "Completed" : "Writing" : "Not provided"} 
            success={isCompleted} 
            disabled={!reviewedByExpert}
            title="View report" 
            path={"report"}
          />
        </CaseDashboardCardContainer>


        {/* Activity */}
        <h4>Last update:</h4>
        <ActivityCard box>
          <Icon icon={getIconByEventTypeName(lastAuditEvent.event)} />
          <div css="margin-right:1rem;">  
            <div><b>{isLegal ? lastAuditEvent.event?.replace("Patient", "Client") : lastAuditEvent.event}</b> {
            // !(lastAuditEvent?.info?.message?.length == 0 && Object.keys(lastAuditEvent?.info).length == 1 && lastAuditEvent?.info?.message) 
            // && (lastAuditEvent?.message?.length > 0 || Object.keys(lastAuditEvent?.info).length > 0 )
              shouldShowLogDetails(lastAuditEvent) 
                ? <a className="link" onClick={showDetails.toggle}>Details</a> 
                : null}
            </div>
            <TransitionDiv>
              {showDetails.isOpen && lastAuditEvent?.info?.reason?.length > 0 && <DetailContainer><strong>Reason: </strong>{lastAuditEvent?.info?.reason}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.REASON?.length > 0 && <DetailContainer><strong>Reason: </strong>{lastAuditEvent?.info?.REASON}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.message?.length > 0 && <DetailContainer><strong>Message: </strong>{lastAuditEvent?.message}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.recordDescription?.length > 0 && <DetailContainer><strong>Medical Record: </strong>{lastAuditEvent?.info?.recordDescription}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.expert?.length > 0 && <DetailContainer><strong>Expert: </strong>{lastAuditEvent?.info?.expert}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.recipients?.length > 0 && <DetailContainer><strong>Recipients: </strong>{lastAuditEvent?.info?.recipients.replaceAll(',',', ')}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.sendSecurely != null && <DetailContainer><strong>Send Securely: </strong>{lastAuditEvent?.info?.sendSecurely ? 'Yes' : 'No'}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.expiration != null && <DetailContainer><strong>Expiration: </strong>{lastAuditEvent?.info?.expiration == 0 ? 'Never' : lastAuditEvent?.info?.expiration == 48 ? '48h' : lastAuditEvent?.info?.expiration == 168 ? '1w' : ''}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.routingId != null && <DetailContainer><strong>Routing ID: </strong>{lastAuditEvent?.info?.routingId}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.studyDescription != null && <DetailContainer><strong>Study Description: </strong>{lastAuditEvent?.info?.studyDescription}</DetailContainer>}
              {/* {showDetails.isOpen && lastAuditEvent?.info?.pacsNodeId != null && <DetailContainer><strong>PACS Node ID: </strong>{lastAuditEvent?.info?.pacsNodeId}</DetailContainer>} */}
              {showDetails.isOpen && lastAuditEvent?.info?.pacsNodeName != null && <DetailContainer><strong>PACS: </strong>{lastAuditEvent?.info?.pacsNodeName}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.pacsNodeIp != null && <DetailContainer><strong>Host: </strong>{lastAuditEvent?.info?.pacsNodeIp}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.pacsNodePort != null && <DetailContainer><strong>Port: </strong>{lastAuditEvent?.info?.pacsNodePort}</DetailContainer>}
              {showDetails.isOpen && lastAuditEvent?.info?.pacsNodeTitle != null && <DetailContainer><strong>Title: </strong>{lastAuditEvent?.info?.pacsNodeTitle}</DetailContainer>}
              {/* {showDetails.isOpen && lastAuditEvent?.info?.studyUid != null && <DetailContainer><strong>Study UID: </strong>{lastAuditEvent?.info?.studyUid}</DetailContainer>} */}
              {/* {showDetails.isOpen && lastAuditEvent?.info?.patientName != null && <DetailContainer><strong>Patient Name: </strong>{lastAuditEvent?.info?.patientName}</DetailContainer>} */}
            </TransitionDiv>
            <div className='activityDetail'>{formatDateNoUTC(new Date(lastAuditEvent.createdEpochMili), "MM/dd/yyyy at hh:mm")} by {findUserByEventAndMail(lastAuditEvent.event,lastAuditEvent.who)}</div>
          </div>
          <TextButton onClick={() => viewHistory.toggle()}>View history</TextButton>
        </ActivityCard>


        {/* Pending */}
        {readOnly && wasSentToExpert && <>
          <h4 css="margin-top:2rem;">Pending:</h4>
          <ActivityCard box pendingActivity>
            <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
            <div css="margin-right:1rem;">  
              <div>{caseRequest.state === CASE_CLOSED ? 'This case is already completed.' : 'Expert review'}</div>
              <div className='activityDetail'>{caseRequest.state === CASE_CLOSED 
                ? caseCompletedWithoutReport 
                  ? 'The case has been marked as completed, but no report was generated.'
                  : intl.formatMessage({
                    defaultMessage:"The report is already concluded and available for the patient contact.",
                    description:"Report concluded warning available to contact label"
                  })
                : expertName + ' is currently reviewing this case.'}
              </div>
            </div>
            <TextButton onClick={() => {navigate("../expert")}}>View experts</TextButton>
          </ActivityCard>
        </>}
        {!(caseRequest.state !== WAITING_MEDICAL_RECORDS && !isPendingAskingMore(caseRequest)) && <>
          <h4 css="margin-top:2rem;">Pending:</h4>
          <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest} small />
        </>}
      </Card>}

      {viewHistory.isOpen && <Card>
        <ReturnButton noShadow rounded color="gray240" onClick={()=>viewHistory.toggle()} icon="return">Return to dashboard</ReturnButton>
        <PageTitle css="margin:2rem 0 1.5rem;">Case history</PageTitle>

        {loadingEvents && <Loading />}

        {!loadingEvents && auditEvents.length === 0 && <p>No history</p>}

        {!loadingEvents && auditEvents.map((auditEvent, index) => {
          
          
          return <ActivityCard timeline={true}>
            <Icon icon={getIconByEventTypeName(auditEvent.event)} />
            <div>
              <div><b>{isLegal ? auditEvent.event?.replace("Patient", "Client") : auditEvent.event}</b> {
                shouldShowLogDetails(auditEvent)
                  ? <a className="link" onClick={() => {
                      let isDetailsOpen = auditEventsDetailsToggle.indexOf(index) >= 0
                      if(isDetailsOpen){
                        setAuditEventsDetailsToggle(details => details.filter(item => item != index ))
                      }else{
                        setAuditEventsDetailsToggle(details => details.concat(index))
                      }
                    }}>Details</a> 
                  : null}
              </div>
              <TransitionDiv>
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.reason?.length > 0 && <DetailContainer><strong>Reason: </strong>{auditEvent?.info?.reason}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.REASON?.length > 0 && <DetailContainer><strong>Reason: </strong>{auditEvent?.info?.REASON}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.message?.length > 0 && <DetailContainer><strong>Message: </strong>{auditEvent?.message}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.recordDescription?.length > 0 && <DetailContainer><strong>Medical Record: </strong>{auditEvent?.info?.recordDescription}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.expert?.length > 0 && <DetailContainer><strong>Expert: </strong>{auditEvent?.info?.expert}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.recipients?.length > 0 && <DetailContainer><strong>Recipients: </strong>{auditEvent?.info?.recipients.replaceAll(',',', ')}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.sendSecurely != null && <DetailContainer><strong>Send Securely: </strong>{auditEvent?.info?.sendSecurely ? 'Yes' : 'No'}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.expiration != null && <DetailContainer><strong>Expiration: </strong>{auditEvent?.info?.expiration == 0 ? 'Never' : auditEvent?.info?.expiration == 48 ? '48h' : auditEvent?.info?.expiration == 168 ? '1w' : ''}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.routingId != null && <DetailContainer><strong>Routing ID: </strong>{auditEvent?.info?.routingId}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.studyDescription != null && <DetailContainer><strong>Study Description: </strong>{auditEvent?.info?.studyDescription}</DetailContainer>}
                {/* {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.pacsNodeId != null && <DetailContainer><strong>PACS Node ID: </strong>{auditEvent?.info?.pacsNodeId}</DetailContainer>} */}
                {auditEventsDetailsToggle.indexOf(index) >= 0  && auditEvent?.info?.pacsNodeName != null && <DetailContainer><strong>PACS: </strong>{auditEvent?.info?.pacsNodeName}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0  && auditEvent?.info?.pacsNodeIp != null && <DetailContainer><strong>Host: </strong>{auditEvent?.info?.pacsNodeIp}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0  && auditEvent?.info?.pacsNodePort != null && <DetailContainer><strong>Port: </strong>{auditEvent?.info?.pacsNodePort}</DetailContainer>}
                {auditEventsDetailsToggle.indexOf(index) >= 0  && auditEvent?.info?.pacsNodeTitle != null && <DetailContainer><strong>Title: </strong>{auditEvent?.info?.pacsNodeTitle}</DetailContainer>}
                {/* {auditEventsDetailsToggle.indexOf(index) >= 0 &&  auditEvent?.info?.studyUid != null && <DetailContainer><strong>Study UID: </strong>{auditEvent?.info?.studyUid}</DetailContainer>} */}
                {/* {auditEventsDetailsToggle.indexOf(index) >= 0 && auditEvent?.info?.patientName != null && <DetailContainer><strong>Patient Name: </strong>{auditEvent?.info?.patientName}</DetailContainer>} */}
              </TransitionDiv>
              <div className='activityDetail'>{formatDateNoUTC(new Date(auditEvent.createdEpochMili), "MM/dd/yyyy at hh:mm")} by {findUserByEventAndMail(auditEvent.event, auditEvent.who)}</div>
            </div>
          </ActivityCard>
        })}
        
      </Card>}
      
    </PageContainer>
    
    <PurviewFooter/>
  </>
}
