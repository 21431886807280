import {useNavigate} from 'react-router'
import {Button, Dialog, Icon, Loading, setNotification} from '@startlibs/components';
import React, { useEffect, useRef, useState } from 'react'
import {CASE_CLOSED} from '../enums/CaseState';
import {Header} from '../components/Header'
import {Card, HeaderButtons, PageContainer} from '../components/PageLayout';
import {Preview} from '../request/reviewedRequest/Preview'
import {PurviewFooter} from '../components/PurviewFooter'
import {SuccessBox} from '../components/InfoBox';
import {getJwt} from '../hooks/useJwt'
import {useShareDialog} from '../hooks/useShareDialog'
import {lazyProviderInfo} from '../components/WithProvider'
import {PdfReportFrame, PdfReportFrameWrapper} from '../request/components/PdfReportFrame'
import {useIframeIsLoading} from '../admin/steps/hooks/useIframeIsLoading'
import {PreviewForCaseRequest} from '../request/reviewedRequest/PreviewForCaseRequest'
import {PreviewForReport} from '../request/reviewedRequest/PreviewForReport'
import {Load} from '../hooks/useSuspense'
import { PatientFeedback } from '../components/PatientFeedback';
import styled from 'styled-components';
import { jwtGetFetcher } from '../utils/authFetch';
import { AssignmentStatusBox } from '../admin/steps/expertReview/ExpertReviewList';

const PageTitle = styled.h1 `
  font-size: 21px;
  margin-bottom: 1rem;
  flex-shrink; 0;
`

export const PatientReport = ({caseRequest,feedbackPopup}) => {

  const providerInfo = lazyProviderInfo.read()
  const [hasFeedback, setHasFeedback] = useState(false)
  const [justSentFeedback, setJustSentFeedback] = useState(false)
  const isSharedRO = caseRequest.readOnlyAcessEmail != null || caseRequest.requestCode.includes('RO-')
  
  useEffect(() => {
    jwtGetFetcher(getJwt())(`/api/feedback`)
      .then((response) => {
        setHasFeedback(true)
      })
      .catch((error) => {
        setHasFeedback(false)
      })
  },[])

  // Fullstory disabled for now
  // useEffect(() => {
  //   window.FS.setUserVars({
  //     displayName: caseRequest.requestCode,
  //     requestCode : caseRequest.requestCode,
  //     requestId: caseRequest.requestId,
  //     type: "PATIENT",
  //     customer: providerInfo.name
  //   })
  //   var now = new Date()
  //   var eventProperties = {
  //     customer_str: providerInfo.name,
  //     dateTime_date: now,
  //     requestId: caseRequest.requestId,
  //     caseId_str: caseRequest.requestCode,
  //     caseState_str: caseRequest.state
  //   }
  //   window.FS.event('Patient Report Accessed',eventProperties)
  // },[])

  const navigate = useNavigate
  const iframeIsLoading = useIframeIsLoading(caseRequest.report?.useCustomReport)

  const [openShareDialog, shareDialog] = useShareDialog(caseRequest)
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  
  return <> <PageContainer withPrint={true}>
    <Header title="Expert View report" noPrint>
      {caseRequest.state === CASE_CLOSED && <SuccessBox>
        <Icon icon="check" /><span>Expert View complete.</span>
      </SuccessBox>}
    </Header>
    {caseRequest.state === CASE_CLOSED && !caseCompletedWithoutReport &&
      <HeaderButtons noPrint>
        <div className="left-wrapper">
          <Button.Link to="/patient/overview">Go to overview</Button.Link>
        </div>
        <div className="right-wrapper">
          <Button.a href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`} icon="download">Save PDF</Button.a>
          <Button.a target="_blank" href={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}`} icon="print">Print</Button.a>
          {!isSharedRO && <Button highlight onClick={openShareDialog} icon="send">Share</Button>}
        </div>
      </HeaderButtons>
    }
    {
      caseCompletedWithoutReport 
        ? <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
            <div className="waitingBoxContent">
              <Icon icon={'check'}/>
              <h4>
                {'This case is already completed.'}
              </h4>
              <p>The case has been marked as completed, but no report was generated.</p>
            </div>
          </AssignmentStatusBox> 
        : caseRequest.report?.useCustomReport
          ? <PdfReportFrameWrapper>
            <PdfReportFrame
              pdfReport
              onLoad={iframeIsLoading.close}
              src={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}&refreshPdf=${caseRequest.pdfKey}#toolbar=0&navpanes=0`}
            />
            {iframeIsLoading.isOpen && <Loading absolute/>}
          </PdfReportFrameWrapper>
          : <Load value={lazyProviderInfo}>{providerInfo =>
            <PreviewForReport
              report={caseRequest.report}
              info={providerInfo}
            />
          }</Load>
    }
    {caseRequest.state === CASE_CLOSED &&
      <HeaderButtons noPrint>
        <div className="left-wrapper">
          {/* <Button onClick={() => navigate(`/patient/overview`)}>Go to overview</Button> */}
          <Button.Link to="/patient/overview">Go to overview</Button.Link>
        </div>
        {!caseCompletedWithoutReport && <div className="right-wrapper">
          <Button.a href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`} icon="download">Save PDF</Button.a>
          <Button.a target="_blank" href={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}`} icon="print">Print</Button.a>
          {!isSharedRO && <Button highlight onClick={openShareDialog} icon="send">Share</Button>}
        </div>}
      </HeaderButtons>
    }
    {
      shareDialog
    }
    {!hasFeedback && !justSentFeedback && caseRequest.requestId && <Card css="margin-top: 5rem" noPrint>
      <PageTitle>Share feedback</PageTitle>
      <PatientFeedback caseRequest={caseRequest} setJustSentFeedback={setJustSentFeedback}/>
    </Card>}
    {justSentFeedback && <Card css="margin-top: 5rem">
      <h3 css="margin-bottom:0;text-align:center;">Thank you for your feedback!</h3>
    </Card>}
    {feedbackPopup.isOpen && <Dialog title="Share feedback" closeDialog={feedbackPopup.close}>
      <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
    </PageContainer>
    <PurviewFooter/>
  </>
}
