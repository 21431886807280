import { callIfFunction, parseDate } from '@startlibs/utils';
import {useRefState, useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled, { createGlobalStyle } from 'styled-components';
import {
  CoverFooter,
  CoverMainWrapper,
  CoverNote,
  CoverTitle,
  REPORT_HEADERS,
  ReportByPurview,
  ReportField
} from '../../admin/steps/finalReport/reportPages/ReportFrontCover';
import {DATE_FORMAT_STR, MonthDayYear} from '../../enums/ReportDateFormat'
import {ExpertProfileItem} from '../../admin/steps/finalReport/reportPages/ReportExpertsProfiles'
import {
  InsertedSignaturesWrapper,
} from '../../expert/ExpertSignatureManagement';
import {LOGO_RIGHT_INFO_LEFT} from '../../enums/ReportHeaderLayout'
import { NotProvided } from '../../admin/steps/ExpertAssignmentStep';
import {SignatureItem} from '../../admin/steps/finalReport/reportPages/ReportSignatures'
import {
  TopSectionHeading,
} from '../../admin/steps/finalReport/FinalReportEdit';
import {
  calculateAge,
  formatDateNoUTC,
  getReportResource,
  isRichText,
  safeMultipleNewLineToBr,
  safeNewLineToDoubleBr
} from '../../utils/utils';
import {clearPagination, getEmptySpace, getLastDiff, getLineHeight, getPageBreak, getSeparators} from './utils'
import {decode} from '../../utils/caseHelper'
import {en_US} from '../../components/date.i18n/en_US'
import {i18nDateMessages} from '../../UIConfig/i18nDateMessages'
import {isStrEmpty} from '../../utils'
import {useSuspense} from '../../hooks/useSuspense'
import {FormattedMessage, useIntl} from 'react-intl'
import { RichTextDisplay } from '../../components/RichTextDisplay';
import { BackCoverMainWrapper, BackCoverNotesWrapper, MarketingMessage, ThankNote } from '../../admin/steps/finalReport/reportPages/ReportBackCover';
import { useOnLoadImages } from '../../hooks/useOnLoadImages';
import { lazyProviderInfo } from '../../components/WithProvider';

const PageResizerContainer = styled.div`
  overflow: hidden;
  .report-page {
    .page-break:after {
      background-color: #f2f2f2;
    }
    .report-footer {
      position: absolute;
      bottom: 0mm;
      right: 0mm;
      left: 0mm;
      border: 0px;
      font-size: 12px;
      text-align: left;
    }
  }
  .page-counter {   
      z-index: 20;
  }
  .clear-border {
    position: absolute;
    z-index: 10;
    // border: 4px solid white;
    border-top: 0;
    border-bottom: 0;
    left: 74px;
    right: 74px;
    background: white;
  }
  .clear-border-top {
    height: 95px;
    margin-top: -95px;
    background: linear-gradient(0deg, white 80%, transparent 20%);
  }
  .clear-border-bottom {
    height: 120px;
    margin-top: 2rem;    
  }
  @media print {
    .clear-border-top {
      height: 95px;
      margin-top: -95px;
      background: transparent;
    }
    .clear-border-bottom {
      height: 120px;
      margin-top: 0rem;    
    }
  }
`

const PAPER_SIZES = {
  A4: [8.27, 11.69],
  LETTER: [8.5, 11]
}

const PrintReportStyle = createGlobalStyle`
  @page {
    margin: 0;
  }
`

const FullPage = styled.div`
  position: absolute;
  top: 0;
  left:0;
  right:0;
  height: ${props => (props.paperSize[1] * 96) + "px"};
  padding: 20mm 20mm 25mm 20mm;
  .cover-filler {
    position: relative;
    height: 100%;
    width: 100%;
  }
`

export const PreviewFromUrl = (props) => {

  const id = new URLSearchParams(window.location.search).get("id")

  const studyCase = {...decode(useSuspense(`clinical_presentation_get.php?id=${id}`)),id}

  return <Preview {...props} studyCase={studyCase}/>
}

const dateMessages = (type,n) => en_US[type+'s'][n]

export const Preview = (
  {
    previewReady,
    organization,
    report,
    resourceOrigin = "",
    paperSize = PAPER_SIZES.LETTER,
    isPdfPrinter
  }) => {

  const intl = useIntl()
  
  const hasCoverPages = true
  const reportData = report.reportData || {}
  const reportLayout = reportData?.reportLayout || {headerLayout: LOGO_RIGHT_INFO_LEFT,dateFormat:MonthDayYear}
  const disclaimer = reportLayout.disclaimerText && !isStrEmpty(report.reportData.disclaimer || "") && report.reportData.disclaimer
  const patientDoB = report.patientInfo.dob && parseDate(report.patientInfo.dob, new Date(), "MM-dd-yyyy")

  const clinicalSummary = (reportLayout.clinicalSummary && !isStrEmpty(report.clinicalSummary))
    ? [{label:intl.formatMessage({defaultMessage:'Reason for seeking medical opinion:',description:'Preview clinical summary label'}),value:report.clinicalSummary}]
    : []

  const caseSummary = (!organization.hideCaseSummaryFromReport && !reportLayout.hideCaseSummary && !isStrEmpty(report.caseSummary))
    ? [{label:intl.formatMessage({defaultMessage:'Clinical summary:',description:'Preview case summary label'}),value:report.caseSummary}]
    : []

  const additionalFields = reportLayout.additionalFields
    ? _.filter(({hide}) => !hide,report.additionalFields).map(({title,content})=> ({label:title,value:content}))
    : []

  const questionsAndAnswers = reportLayout.questionsConsultant
    ? _.filter(({hide})=>!hide,report.questionsConsultant)
    : []


  const visibleExperts = reportLayout.expertProfileDetails
    ? _.filter((expertReview) => !expertReview?.hide,report.expertReviews)
    : []
  const signatures = reportLayout.expertSignatures
    ? _.filter(({hide})=> !hide,report.signatures)
    : []

  const lastPageFooter = true

  const isPreviewDone = useToggle()
  const isLogoLoaded = useToggle()

  const fieldsContainer = useRef()
  const compactHeaderContainer = useRef()
  const reportContainer = useRef()
  const footerFiller = useRef()
  const reportFooter = useRef()
  const reportDisclaimer = useRef()
  const reportPaginationPageFooter = useRef()

  const imagesLoaded = useOnLoadImages(reportContainer);
  
  // resize props
  const totalHeight = useRefState(null)
  const activeScale = useRefState(1)

  const resize = () => {
    if (!reportContainer.current || isPdfPrinter || !imagesLoaded) return;
    const resizerContainer = reportContainer.current.parentNode
    const diff = resizerContainer.scrollWidth - resizerContainer.clientWidth
    if (activeScale.get() === 1 && diff <= 0) return;
    const pageWidth = Math.round(paperSize[0] * 96)
    if (totalHeight.get() === null) {
      totalHeight.set(parseInt(window.getComputedStyle(resizerContainer).height))
    }
    activeScale.set(Math.min(resizerContainer.clientWidth / (reportContainer.current.clientWidth+2), 1))
    reportContainer.current.style.transform = "scale(" + activeScale.get() + ")";
    reportContainer.current.style.transformOrigin = "left top";
    resizerContainer.style.height = (totalHeight.get() * activeScale.get()) + "px"
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  useEffect(() => {
    isPreviewDone.close()
    isLogoLoaded.close()
    const imagesToLoad = [
      report.institutionDetails?.logoPath ? getReportResource(report.institutionDetails.logoPath,resourceOrigin) : organization.logoURL,
      ...visibleExperts.map(_.flow(_.get('picture'),(s) => getReportResource(s,resourceOrigin))).filter(Boolean),
      ...signatures.map(_.flow(_.get('imagePath'),(s) => getReportResource(s,resourceOrigin))).filter(Boolean),
      ...caseSummary.map(_.flow(_.get('value'),(text) => Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')).length > 0 ? Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')) : null)).filter(Boolean).flat(),
      ...additionalFields.map(_.flow(_.get('value'),(text) => Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')).length > 0 ? Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')) : null)).filter(Boolean).flat(),
      ...questionsAndAnswers.map(_.flow(_.get('answer'),(text) => Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')).length > 0 ? Array.from(new DOMParser().parseFromString(text, "text/html").querySelectorAll("img")).map(_.get('src')) : null)).filter(Boolean).flat()
    ].map(imageSrc =>
      new Promise((resolve, reject) => {
        const img = document.createElement('img')
        img.onload = resolve
        img.onerror = resolve
        img.src = imageSrc
      })
    )
    Promise.all(imagesToLoad).then(loaded => isLogoLoaded.open(),loaded => isLogoLoaded.open())
  },[previewReady])

  useEffect(() => {
    reportPaginationPageFooter.current.className = 'report-page-footer'
    compactHeaderContainer.current.style.display = 'block'
    footerFiller.current.style.height = '0'
    reportFooter.current.style.display = 'block'


    clearPagination(reportContainer.current)

    try {
      addPreviewPagination()
    } catch (e) {
      reportPaginationPageFooter.current.className = 'report-page-footer-failed'
      clearPagination(reportContainer.current)
    }
    if (!lastPageFooter)
      reportFooter.current.style.display = 'none'
    compactHeaderContainer.current.style.display = 'none'
    reportContainer.current.style.height = 'auto'
    if (!isPdfPrinter) {
      resize()
    }
    isPreviewDone.open()

  },[previewReady,imagesLoaded])

  useEffect(() => {
    if (isPreviewDone.isOpen && isLogoLoaded.isOpen) {
      callIfFunction(previewReady,paperSize)
    }
  },[isPreviewDone.isOpen, isLogoLoaded.isOpen])


  const addPreviewPagination = () => {

    footerFiller.current.style.height = 0
    reportContainer.current.style.width = Math.round(paperSize[0] * 96) + 'px'
    reportContainer.current.style.height = (Math.round(paperSize[1] * 96) +
      parseInt(getComputedStyle(reportContainer.current).borderTopWidth, 10) +
      parseInt(getComputedStyle(reportContainer.current).borderBottomWidth, 10)) + 'px'
    const {height: paperHeight, top: containerTopWithBorder} = reportContainer.current.getBoundingClientRect()
    const containerTop = containerTopWithBorder
    const reportContainerStyle = window.getComputedStyle(reportContainer.current)
    const contentMarginTop = (parseFloat(reportContainerStyle.paddingTop, 10) || 0) + (parseFloat(reportContainerStyle.borderTop, 10) || 0)
    const contentMarginBottom = (parseFloat(reportContainerStyle.paddingBottom, 10) || 0) + (parseFloat(reportContainerStyle.borderBottom, 10) || 0)
    const contents = Array.prototype.slice.call(fieldsContainer.current.querySelectorAll('.field-content,.field-content-block,.always-break-here'))
    const lineHeight = getLineHeight(contents)
    const minBreakSize = lineHeight * 3
    const minContentSizeToBreak = lineHeight * 6

    const compactHeaderHeight = compactHeaderContainer.current.offsetHeight
    const reportFooterHeight = lastPageFooter ? reportFooter.current.offsetHeight : 0

    const contentPageHeight = paperHeight - contentMarginBottom - contentMarginTop - compactHeaderHeight
    const contentLastPageHeight = contentPageHeight - reportFooterHeight

    const top = containerTop + contentMarginTop + compactHeaderHeight

    const {top: lastContentsTop, separators} = getSeparators(contents, top, {
      contentPageHeight,
      minBreakSize,
      contentLastPageHeight,
      canBeLastPage: true,
      lastPageIsCover: true,
      minContentSizeToBreak
    }, 0, true)

    const footerDiff = Math.floor(
      lastContentsTop + contentPageHeight -
      footerFiller.current.getBoundingClientRect().bottom -
      getLastDiff(separators) +
      // TODO FIX THIS:
      (separators.length === 0 ? -20 : 0)
    )

    footerFiller.current.style.height = (footerDiff) + 'px'

    separators.reverse().forEach(({diff, position, node}, i) => {
      const page = separators.length - 1 - i
      // console.log(page)
      if (position !== undefined) {
        const range = document.createRange()
        range.setStart(node, position)
        range.setEnd(node, position)
        range.insertNode(getPageHeader(i===0))
        range.insertNode(getPageBreak(page, separators.length, hasCoverPages && page === 0))
        // if (page === 0 && organization.reportDisclaimer) {
        //   range.insertNode(reportDisclaimer.current)
        // }
        range.insertNode(getEmptySpace(diff))
      } else {
        node.parentNode.insertBefore(getEmptySpace(diff), node)
        // if (page === 0 && organization.reportDisclaimer) {
        //   node.parentNode.insertBefore(reportDisclaimer.current,node)
        // }
        node.parentNode.insertBefore(getPageBreak(page, separators.length, hasCoverPages && page === 0), node)
        node.parentNode.insertBefore(getPageHeader(i===0), node)
      }
    })
    if (separators.length === 0) {
      // if (organization.reportDisclaimer) {
      //   footerFiller.current.parentNode.insertBefore(reportDisclaimer.current,reportPaginationPageFooter.current)
      // }
      reportFooter.current.style.display = 'none'
    }
    // if (organization.reportDisclaimer) {
    //   reportDisclaimer.current.previousSibling.className = ''
    // }
  }

  const getPageHeader = (invisible) => {
    const clone = compactHeaderContainer.current.cloneNode(true)
    const height = compactHeaderContainer.current.offsetHeight
    const container = document.createElement('div')
    container.style.height = height + 'px'
    container.style.marginBottom = '2px'
    if (invisible) {
      container.style.visibility = 'hidden'
    }
    container.className = 'report-header-fix-container'
    container.appendChild(clone)
    return container
  }


  const patientName = (report.patientInfo.firstName || report.patientInfo?.middleName || report.patientInfo.lastName)
      ? <>{report.patientInfo.firstName} {report.patientInfo?.middleName} {report.patientInfo.lastName}</>
      : report.patientInfo.patientName

  return ( <>
    <PrintReportStyle />
    <PageResizerContainer>
        <div ref={reportContainer} className="report-page">
          {
            REPORT_HEADERS[reportLayout.headerLayout](report.institutionDetails,organization,true,resourceOrigin)
          }
          <FullPage paperSize={paperSize}><div className="cover-filler">
            <CoverMainWrapper>
              <CoverTitle enhancedContrastColor={reportData?.customColors?.enhancedContrastColor ? reportData?.customColors?.enhancedContrastColor : false}> 
                <h1 className="title" id='report-title'>
                  {reportData.title || "Case report"}
                </h1>
                <div className="report-date">
                  {
                    formatDateNoUTC(report.whenReturnedEpochMilli ? new Date(report.whenReturnedEpochMilli) : new Date(), DATE_FORMAT_STR[reportLayout.dateFormat], i18nDateMessages)
                  }
                  <span className="case-id" id="case-id"> (Case ID: {report.id})</span>
                </div>
                <div className="patient-info">
                  <div className="name" id="patient-name">
                    <label style={{color: "#636362"}}><FormattedMessage defaultMessage="Patient" description="patient label"/></label>
                    <div><strong className='fs-exclude'>
                      {patientName}
                    </strong></div>
                  </div>
                  {
                    patientDoB && <div className="dob">
                    <label style={{color: "#636362"}}>Date of birth</label>
                    <div><strong className='fs-exclude'>
                      {formatDateNoUTC(patientDoB, "MM/dd/yyyy")} ({calculateAge(patientDoB)} years old)
                    </strong></div>
                  </div>
                  }
                </div>
              </CoverTitle>
              {reportData.coverNote &&
                <CoverNote isPreview>
                  {reportData.coverNote}
                </CoverNote>
              }
            </CoverMainWrapper>
            <CoverFooter isPreview>
              <ReportByPurview>
                Created with Purview Expert View™
              </ReportByPurview>
              {
                disclaimer &&
                <ReportField isDisclaimer isPreview>
                  <div className="field-content" ref={reportDisclaimer}>
                    <strong>Disclaimer:</strong> {disclaimer}
                  </div>
                </ReportField>
              }
            </CoverFooter>
          </div></FullPage>
          <div ref={fieldsContainer}>
            <div className="always-break-here"/>
            {
              visibleExperts.length > 0 && <>
                <section role='region' aria-labelledby="Experts">
                  <TopSectionHeading enhancedContrastColor={reportData?.customColors?.enhancedContrastColor ? reportData?.customColors?.enhancedContrastColor : false}>
                    <h2 id='Experts'><FormattedMessage description='Preview expert physician title' defaultMessage='Expert physician' /></h2>
                    <p>This expert review was provided by:</p>
                  </TopSectionHeading>
                  {visibleExperts.map((expert) => <ExpertProfileItem resourceOrigin={resourceOrigin} showEdit={expert.showEdit} expert={expert} />)}
                  <div className="always-break-here"/>
                </section>
              </>
            }
            <section role='region' aria-labelledby="Expert review">
              <TopSectionHeading enhancedContrastColor={reportData?.customColors?.enhancedContrastColor ? reportData?.customColors?.enhancedContrastColor : false}>
                <h3 id='expert-review'>Expert review</h3>
              </TopSectionHeading>
            </section>
            <section role='region' aria-labelledby="Summary">
              {clinicalSummary.map(field =>
                <ReportField key={field.label} className="NODE-TO-BREAK" isPreview>
                  <div className="field-heading fs-exclude">
                    <h4>{field.label}</h4>
                  </div>
                  {field.value ?
                    <div className="field-content fs-exclude" dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(field.value)}}/>
                    : <NotProvided css="margin-top: .5rem; display: block;"/>
                  }
                </ReportField>
              )}
              {caseSummary.map(field =>
                <ReportField key={field.label} className="NODE-TO-BREAK" isPreview>
                  <div className="field-heading fs-exclude">
                    <h4>{field.label}</h4>
                  </div>
                  {field.value ?
                    // <div className="field-content" dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(field.value)}}/>
                    // <div className="field-content" dangerouslySetInnerHTML={{__html: isRichText(field.value) ? field.value : safeNewLineToDoubleBr(field.value)}}/>
                    <RichTextDisplay className={"field-content fs-exclude"} content={field.value} useSafeNewLine={!isRichText(field.value)} />
                    : <NotProvided css="margin-top: .5rem; display: block;"/>
                  }
                </ReportField>
              )}
            </section>
            {questionsAndAnswers.length > 0 && <>
              <section role='region' aria-labelledby="Questions & answers">
                <ReportField>
                  <div className="field-heading">
                    <h4>Questions & answers</h4>
                  </div>
                </ReportField>
                {questionsAndAnswers.map(({question,answer},i)=>
                  <div key={question+i} className="NODE-TO-BREAK question-and-answer">
                    <div style={{color: "#636362"}}>Question:</div>
                    <div className="field-content question fs-exclude" dangerouslySetInnerHTML={{__html: question}}/>
                    {/* {answer && <div className="field-content answer" dangerouslySetInnerHTML={{__html: safeNewLineToDoubleBr(answer)}}/>} */}
                    {answer && <div style={{color: "#636362", marginTop: "0.5rem" }}>Answer:</div>}
                    {answer && <RichTextDisplay className="field-content answer fs-exclude" content={answer} useSafeNewLine={!isRichText(answer)}/>}
                  </div>)}
              </section>
            </>}

            {additionalFields.length > 0 &&
              <ReportField>
                <section role='region' aria-labelledby="Additional information">
                  <div className="field-heading">
                    <h4>Additional information</h4>
                  </div>
                </section>
              </ReportField>
            }
            {additionalFields.map(field =>
              <ReportField key={field.label} className="NODE-TO-BREAK" isPreview subItem>
                <div className="field-heading fs-exclude">
                  {/* <h4>{field.label}</h4> */}
                  <h4><RichTextDisplay content={field.label} /></h4>
                </div>
                {field.value ?
                  // <div className="field-content" dangerouslySetInnerHTML={{__html: isRichText(field.value) ? field.value : safeNewLineToDoubleBr(field.value)}}/>
                  <RichTextDisplay className="field-content fs-exclude" content={field.value} useSafeNewLine={!isRichText(field.value)} />
                  : <NotProvided css="margin-top: .5rem; display: block;"/>
                }
              </ReportField>
            )}
            {signatures.length >= 1 && <div className='NODE-TO-BREAK signature-block'>
              <ReportField className="field-content">
                <div className="field-heading">
                  <h4>Signatures</h4>
                </div>
              </ReportField>
              <InsertedSignaturesWrapper>
                {
                  signatures.map(signature =>
                    <SignatureItem
                      className='field-content signature-item'
                      signature={signature}
                      resourceOrigin={resourceOrigin}
                      isPreview
                    />)
                }
              </InsertedSignaturesWrapper>
              </div>
            }
            <div className="always-break-here"/>
          </div>
          <div className="report-compact-header" ref={compactHeaderContainer}>
            <div className="report-compact-header-content">
              <span>{organization.name}</span>
              <span className='fs-exclude'><FormattedMessage description="patient label" defaultMessage="Patient"/>: {patientName} {patientDoB && <>- DOB: {formatDateNoUTC(patientDoB, "MM/dd/yyyy")}</>}</span>
            </div>
            <div className="report-compact-header-margin"/>
          </div>
          
          <div className="footer-filler" ref={footerFiller}>
            <BackCoverNotesWrapper>
              <ThankNote id='thank-you-note' enhancedContrastColor={reportData?.customColors?.enhancedContrastColor ? reportData?.customColors?.enhancedContrastColor : false}>Thank you for choosing <br/>{report?.institutionDetails?.name ? report?.institutionDetails?.name : organization?.name}</ThankNote>
              {reportData.lastPageNote &&
                <MarketingMessage>
                  {reportData.lastPageNote}
                </MarketingMessage>
              }
            </BackCoverNotesWrapper>

            <BackCoverMainWrapper>
              {/* <CoverMainWrapper> */}
              {
                REPORT_HEADERS[reportLayout.headerLayout](report.institutionDetails,organization,true,resourceOrigin)
              }
              {/* </CoverMainWrapper> */}
            </BackCoverMainWrapper>
            <div className="report-footer" ref={reportFooter}>
              <ReportByPurview>
                Created with Purview Expert View™
              </ReportByPurview>
              {
                disclaimer &&
                <ReportField isDisclaimer isPreview>
                  <div className="field-content" ref={reportDisclaimer}>
                    <strong>Disclaimer:</strong> {disclaimer}
                  </div>
                </ReportField>
              }
            </div>
          </div>
          <div hidden className="report-page-footer" ref={reportPaginationPageFooter}/>
        </div>
    </PageResizerContainer>
  </>)
}
