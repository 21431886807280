import { Button, Icon } from '@startlibs/components';
import {FormValue, SimpleCheckbox, TextInput, useFormValue} from '@startlibs/form'
import { getColor } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {
  BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES,
  OTHER_FACILITIES
} from '../../../enums/MedicalRecordLocationType'
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import { InfoBox } from '../../../components/InfoBox';
import {buildValidation, emailRegex} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtFormFetcher} from '../../../utils/authFetch'
import { useIntl } from 'react-intl';
import { transparentize } from 'polished';

const TYPE_LABEL = {
  [PHYSICIAN_OR_PROVIDER]:"Physician",
  [SCAN_FACILITIES]:"Scan",
  [HOSPITALS]:"Hospital",
  [BIOPSY_FACILITIES]:"Biopsy",
  [OTHER_FACILITIES]: "Other"
}

const preValidation = buildValidation({
  "locationIds": (list) => !list?.length && "Selected at least one facility to request medical records"
})
export const useRequestRecords = (releases = [], caseRequest, release) => {

  const intl = useIntl()

  const validReleases = releases.filter(release => !release.suspend && emailRegex.test(release.medicalRecordLocationItem.email))

  const confirmValues = useToggle({locationIds: release ? [release.id] : [], customMessage: ""})

  const request = useConfirmDialog(<ConfirmDialog
      title="Request records"
      css="max-width:47rem;"
      confirmChanges={false}
      preValidation={preValidation}
      action={(values) => {
        confirmValues.openWith(values);
        setTimeout(confirm, 200)
      }}
      confirm={<Button highlight>Request</Button>}
      values={confirmValues.get()}
    >{form => <>
      <p>
        Please review details below of the case to which requested
        records will be uploaded.
      </p>
      <CaseRequestCard viewOnly caseRequest={caseRequest}/>
      <LocationListHeader>
        <p>Request medical records from:</p>
        <div><FormValue path="locationIds.length">{quantity =>
            <>
              {quantity > 0 && (quantity + " selected")}
              <a className="link" onClick={() => form.setValue('locationIds', validReleases.length === quantity ? [] : validReleases.map(_.get('id')))}>{validReleases.length === quantity ? "Deselect all" : "Select all"}</a>
            </>
          }</FormValue>
        </div>
      </LocationListHeader>
      <LocationList><div>
        {
          validReleases.map(release =>
            <LocationItem release={release}/>)
        }
      </div></LocationList>
      <UnauthorizedRecordsWarning releases={validReleases}/>
      <TextInput
        path="customMessage"
        textarea
        autoResize
        label="Message"
        descText="This will be included in the email sent to the provider(s)."
        placeholder="Insert custom message here."
      />
    </>}</ConfirmDialog>, [confirmValues.isOpen]
  )


  const confirm = useConfirmDialog(<ConfirmDialog
      title="Request records"
      css="max-width:47rem;"
      action={jwtFormFetcher(getJwt())("/api/admin/sendMedicalRecordReleaseToLocation?requestId=" + caseRequest.requestId)}
      confirm={<Button highlight>Request</Button>}
      values={confirmValues.get()}
      closeButton={(closeDialog) => <Button
        onClick={() => {
          closeDialog();
          setTimeout(request, 150)
        }}
      >Cancel</Button>}
      // onSuccess={() => setRelease(_.set('suspend',true))}
      notify="Records requested successfully."
    >
      <p>Please review details below of the case to which requested records will be uploaded.</p>
      <CaseRequestCard viewOnly caseRequest={caseRequest}/>
      <p>Requesting records from <b><FormValue path="locationIds.length">{quantity =>
            quantity > 1 ? (quantity + " facilities") : (quantity + " facility")
          }</FormValue></b>:
      </p>
      <ConfirmationLocationList>
      {confirmValues.get().locationIds
          .map(id => validReleases.find(r => r.id === id))
          .filter(_.identity)
          .map(release => <LocationContent location={release.medicalRecordLocationItem}/>)}
        {confirmValues.get().customMessage}
      </ConfirmationLocationList>
      <p>
        Each of the facilities above will be able to access an online uploader
        and to view the signed medical records release form (if complete.)
      </p>
      <UnauthorizedRecordsWarning releases={validReleases}/>
    </ConfirmDialog>, [confirmValues.isOpen]
  )


  return [request,validReleases.length>0]
}

const UnauthorizedRecordsWarning = ({releases}) => {
  const intl = useIntl()
  const [ids] = useFormValue("locationIds")
  if (releases.find(({id,medicalRecordsReleaseLicense:{signature}}) => ids.indexOf(id)>=0 && !signature)) {
    return <InfoBox lightYellow css="margin-bottom:-0.5rem 0 1.5rem">
      The medical records release has not been signed for this provider.<br></br>
      {intl.formatMessage({
        defaultMessage:"Please consider requesting a signature from the patient contact before proceeding.",
        description:"useRequestRecords UnauthorizedRecordsWarning InfoBox",
      })}
    </InfoBox>
  } else {
    return null
  }
}

const LocationItem = ({release}) => {
  const [values, setValues] = useFormValue('locationIds')
  const isChecked = values.indexOf(release.id) >= 0
  const ref = useRef()
  useEffect(() => {
    if (isChecked && ref.current) {
      ref.current.scrollIntoView({block:"center"})
    }
  },[])
  const toggle = () => setValues((list) => list.indexOf(release.id) >= 0 ? _.without([release.id], list) : list.concat(release.id))
  return <LocationItemStyled ref={ref} onClick={toggle} isChecked={isChecked}>
    <LocationContent
      location={release.medicalRecordLocationItem}
      withPhone
      isUnathourized={!release.medicalRecordsReleaseLicense.signature}
    />
    <div onClick={e => e.stopPropagation()}><SimpleCheckbox
      raw
      setValue={toggle}
      value={isChecked}
    /></div>
  </LocationItemStyled>
}

const LocationItemStyled = styled.div `
  position: relative;
  background: ${getColor('gray240')};
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 0.75rem 1.25rem 0.75rem;
  cursor: pointer;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background: ${props => transparentize(0.85, getColor('main')(props))};
  }
  ${props => props.isChecked && css`
    background: ${getColor('main')};
    color: white;
    .authorizationAlert {
      color: #ffb9b9 !important;
    }
    :hover {
      background: ${getColor('main')};
    }
  `}
  ${SimpleCheckbox} {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`
const LocationListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0;
  }
  .link {
    margin-left: 1rem;
  }
`
const LocationList = styled.div `
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  > div {
    max-height: 15rem;
    overflow: auto;
  }
`

const LocationName = styled.div `
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

const LocationDetail = styled.div `
  margin-top: 2px;
  font-size: 11px;
  span + span {
    margin-left: 1rem;
  }
`

export const LocationContent = styled(({className,location,withPhone,isUnathourized}) => {
  return <div className={className}>
    <LocationName>{location.name} ({TYPE_LABEL[location.locationType]}){isUnathourized &&
    <span className="authorizationAlert"><Icon icon="warning"/><i>Authorization not signed</i></span>}</LocationName>
    <LocationDetail>
      {location.contactName && <span><b>Contact name:</b> {location.contactName}</span>}
      {withPhone && location.phoneNumber && <span><b>Phone:</b> {location.phoneNumber}</span>}
      {location.email && <span><b>Email:</b> {location.email}</span>}
    </LocationDetail>

  </div>
})`
  position: relative;
  .authorizationAlert {
    font-size: 11px;
    font-weight: 400;
    margin-left: 0.5rem;
    color: ${getColor('alert')};
    ${Icon} {
      font-size: 15px;
      margin-right: 0.25rem;
      vertical-align: -2px;
    }
  }
`

const ConfirmationLocationList = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  ${LocationContent} {
      margin-bottom: 2rem;
      :after {
        content: '';
        position: absolute;
        bottom: -1rem;
        width: 2rem;
        border-bottom: 1px solid ${getColor('gray180')};
        left: 0;
      }
      :last-child {
        margin-bottom: 0;
        :after {
          content: none;
        }
      }
  }
`
