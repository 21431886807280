import { Button } from '@startlibs/components';
import {WithForm} from '@startlibs/form'
import { callIfFunction, getColor, wrapLazy } from '@startlibs/utils';
import React, { useEffect, useRef } from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {
  Card,
  HeaderButtons,
  PageContainer
} from '../../../components/PageLayout';
import {Header} from '../../../components/Header'
import {ReportAdditionalFields} from './reportPages/ReportAdditionalFields'
import {ReportBackCover} from './reportPages/ReportBackCover'
import {ReportExpertsProfiles} from './reportPages/ReportExpertsProfiles'
import {ReportFrontCover} from './reportPages/ReportFrontCover'
import {ReportQuestionAndAnswers} from './reportPages/ReportQuestionAndAnswers'
import {ReportSignatures} from './reportPages/ReportSignatures'
import {getJwt} from '../../../hooks/useJwt'
import {jwtFormFetcher, jwtPostFetcher} from '../../../utils/authFetch'
import {setNotification} from '../../../components/Notifications'
import { useRefreshableInstitutions } from './lazyInstitutions';
import {useToggle} from '@startlibs/core'
import {ReportPrimaryConcern} from './reportPages/ReportPrimaryConcern'
import {ReportCaseSummary} from './reportPages/ReportCaseSummary'
import {FormattedMessage} from 'react-intl'

const ReportEditorWrapper = styled(Card)`
  border-radius: 0;
  padding: 20mm 20mm 25mm 20mm;
`

export const TopSectionHeading = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${getColor('main')};
    margin-bottom: 1rem;
    text-align: left;
    ${props => props.enhancedContrastColor && `color: ${props.enhancedContrastColor};`}
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${getColor('main')};
    ${props => props.enhancedContrastColor && `color: ${props.enhancedContrastColor};`}
  }
    
`

const lazySaveDefaultInstitution = wrapLazy((caseRequest,defaultInstitution) =>
  jwtPostFetcher(getJwt())("/api/admin/reportInstitutionDetails?requestId="+caseRequest.requestId,defaultInstitution,{method:"PUT"})
    .then(() => {
      caseRequest.institutionDetails = defaultInstitution
    })
)

export const FinalReportEdit = ({caseRequest,  editing, setCaseRequest, providerInfo}) => {

  const {institutionDetailsList:institutions,defaultInstitutionDetails:defaultInstitution} = useRefreshableInstitutions()

  if (!caseRequest.institutionDetails && defaultInstitution) {
    lazySaveDefaultInstitution.use(caseRequest,defaultInstitution).read()
  }

  useEffect(() => {
    // On Changing the institution, we need to update the institutionDetails on caseRequest
    // Find case request institution and check if equal to institution details. If it has changed, update it
    if (caseRequest.institutionDetails && institutions) {
      const institution = institutions.find(i => i.id === caseRequest.institutionDetails.id)
      if (institution && !_.isEqual(institution,caseRequest.institutionDetails)) {
        setCaseRequest({...caseRequest,institutionDetails:institution})
      }
    }
  },[institutions])

  return <ReportPagesEdit caseRequest={caseRequest} setCaseRequest={setCaseRequest} providerInfo={providerInfo} institutions={institutions} defaultInstitution={defaultInstitution}/>

}

/*
dateFormat
disclaimerText: true
expertProfileDetails: true
frontBackCover: true
headerLayout: "LOGO_LEFT_INFO_RIGHT"
institutionDetails: true
paperSize: "US_LETTER"
 */

const ReportPagesEdit = ({caseRequest,setCaseRequest, providerInfo, institutions, defaultInstitution}) => {
  const formRef = useRef()

  useEffect(() => {
    if (formRef.current.getValues()!==caseRequest.reportData) {
      formRef.current.setValues(_.set('reportData',caseRequest.reportData))
    }
  },[caseRequest.reportData])

  const updateCaseInfo = (type) => (updater) => {
    const newValue = callIfFunction(updater,caseRequest.caseInfo)
    setCaseRequest(_.set('caseInfo',newValue))

    const retry = () => jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, newValue, {method: "PUT"})
      .catch(() => {
        setTimeout(() =>
          setNotification({type:"alert", timeout: 0,msg:(close) => <span>Could not save {type} <a onClick={() => { close(); retry()}}>Retry</a></span>}),
          50
        )

      })
    return retry()
  }

  const reportLayout = caseRequest.reportData.reportLayout
  return <WithForm
    values={caseRequest.reportData}
    ref={formRef}
    transform={_.set('disclaimer',_.get('disclaimer',caseRequest.reportData))}
    action={jwtFormFetcher(getJwt())(`/api/admin/reportData?requestId=${caseRequest.requestId}`, {method: 'PUT'})}
    onSuccess={(reportData) => setCaseRequest(_.set('reportData', reportData))}
  >{form => <>
    <ReportEditorWrapper>
      {
        reportLayout.frontBackCover &&
        <ReportFrontCover
          caseRequest={caseRequest}
          setCaseRequest={setCaseRequest}
          form={form}
          reportLayout={reportLayout}
          providerInfo={providerInfo}
          defaultInstitution={defaultInstitution}
          institutions={institutions}
        />
      }
      {
        reportLayout.expertProfileDetails && <>
        <TopSectionHeading enhancedContrastColor={providerInfo?.customColors?.enhancedContrastColor ? providerInfo.customColors.enhancedContrastColor : false}>
          <h3><FormattedMessage description='Preview expert physician title' defaultMessage='Expert physician' /></h3>
          <p>This expert review was provided by:</p>
        </TopSectionHeading>
        <ReportExpertsProfiles form={form} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
        </>
      }
      <TopSectionHeading enhancedContrastColor={providerInfo?.customColors?.enhancedContrastColor ? providerInfo.customColors.enhancedContrastColor : false}>
        <h3>Expert review</h3>
      </TopSectionHeading>
      <ReportPrimaryConcern caseRequest={caseRequest} form={form}/>
      {!providerInfo.hideCaseSummaryFromReport && <ReportCaseSummary caseRequest={caseRequest} form={form}/>}
      <ReportQuestionAndAnswers updateCaseInfo={updateCaseInfo('question')} caseRequest={caseRequest} setCaseRequest={setCaseRequest} form={form}/>
      <ReportAdditionalFields updateCaseInfo={updateCaseInfo('field')} caseRequest={caseRequest} setCaseRequest={setCaseRequest} form={form}/>
      <ReportSignatures updateCaseInfo={updateCaseInfo('signature')} caseRequest={caseRequest} setCaseRequest={setCaseRequest} form={form}/>

      {
        reportLayout.frontBackCover &&
        <ReportBackCover
          caseRequest={caseRequest}
          setCaseRequest={setCaseRequest}
          form={form}
          reportLayout={reportLayout}
          providerInfo={providerInfo}
          defaultInstitution={defaultInstitution}
          institutions={institutions}
        />
      }
    </ReportEditorWrapper>
    </>
  }</WithForm>
}
