import React from 'react'
import _ from 'lodash/fp'
import {lazyProviderInfo} from '../../components/WithProvider'
import {Load} from '../../hooks/useSuspense'
import {lazyExperts} from '../../admin/steps/finalReport/reportPages/lazyExperts'
import {Preview} from './Preview'


export const PreviewForReport = ({
                                        report,
                                        info,
                                        ...props
                                      }) => {

  // If there is info.customColors, we are in the web app, so we need to update the customColors in the report
  // Ensure reportData is always assigned, even if info.customColors is falsy
  let reportData = {
    ...report.reportData,
    ...(info?.customColors ? { customColors: info.customColors } : {}),
  };

  return <Preview
        {...props}
        report={{
          ...report,
          reportData: reportData,
          patientInfo:{firstName:report.firstName,middleName:report.middleName,lastName:report.lastName,patientName: report.patientName, dob: report.dob},
          expertReviews:_.map((expert) => ({...expert,info:{specialization:expert.specialization,resume:expert.resume}}) ,report.expertReviews)
        }}
        organization={{...info, ...info.address}}
      />
}