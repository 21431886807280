import {Button, Icon, Link, Tooltip} from '@startlibs/components';
import {desaturate, lighten, transparentize} from 'polished';
import {getColor, preventDefault, rafThrottle} from '@startlibs/utils'
import {useEnsureRef} from '@startlibs/core';
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {AdminCaseCardCategories, CategoryTag} from '../dashboard/AdminCaseCardCategories'
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  CASE_DRAFT,
  CASE_REJECTED,
  CASE_REVIEWED,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  REQUEST_REJECTED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState';
import {ScrollToTop} from '../../components/ScrollToTop'
import {isStateBefore} from '../../request/utils';
import {lazyProviderInfo, lazyUserInfo} from '../../components/WithProvider'
import {useEditCategories} from '../experts/hooks/useEditCategories'
import {useToggleAsPriority} from '../hooks/useMarkAsPriority'
import {useReadOnlyShare} from './hooks/useReadOnlyShare'
import {IfLocale} from '../../hocs/IfLocale'
import { useEditCaseNumber } from '../experts/hooks/useEditCaseNumber';
import {Match} from "../../components/Match";
import { confirmExitPage } from '../../hooks/useConfirmExitPage';
import { useNavigate } from 'react-router';
import { useAskContributionShare } from './hooks/useAskContributionShare';
import { useShareCompleted } from './hooks/useShareCompleted';
import { REVIEWER_TYPE_ADMIN } from '../../enums/UserRole';

export const SidebarContainer = styled.div`
  position: sticky;
  align-self: flex-start;
  top: 0rem;
  width: 23rem;
  padding: 9.5rem 2rem 3rem 2rem;
  flex-shrink: 0;
  ${props => props.isReport && css`
    @media print {
      display: none;
    }`
  }
`

export const Sidebar = styled(React.forwardRef((props, ref) => {

  const ensuredRef = useEnsureRef(ref)

  React.useEffect(() => {
    let lastPosition = window.pageYOffset;
    let currentY = 0
    const scrollEvent = rafThrottle(() => {
      const delta = window.pageYOffset - lastPosition
      if (!ensuredRef.current) return;
      const {top, height} = ensuredRef.current.getBoundingClientRect()
      const originalTop = top - currentY
      const max = Math.max(0, height + originalTop - window.innerHeight)
      currentY = Math.max(-max, Math.min(currentY - delta, 0))
      ensuredRef.current.style.transform = `translateY(${currentY}px)`
      lastPosition = window.pageYOffset
    })
    window.addEventListener('scroll', scrollEvent)
    return () => window.removeEventListener('scroll', scrollEvent)
  }, [])

  return <div ref={ensuredRef} {...props}/>
}))`
  padding-bottom: 2rem;
`

export const SidebarCard = styled.div`
  padding: 1rem 0;
  background: white;
  border-radius: 8px;
  margin-bottom: 1.25rem;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  .sidebar-heading {
    margin-bottom: 1rem;
    position: relative;
  }
  .sidebar-icons {
    position: absolute;
    top: 0.25rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: ${getColor('gray180')};
    ${props => !props.isExpert && css`
      cursor: pointer;
    `}
  }
  .priority-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
    font-size: 20px;
    margin-left: 0.5rem;
    :hover {
      ${props => !props.isPriority && css`
        color: ${getColor('gray120')};
      `}
    }
    ${Icon} {
      margin-top: -2px;
    }
    ${props => props.isPriority && css`
      color: ${getColor('warning')};
      background-color: ${getColor('lightYellow')};
      ${props => !props.isExpert && css`
        :hover {
          opacity: 75%;
        }
    `}
  `}
`
export const StepLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgba(0,0,0,0.8);
  padding: 9px 2rem 9px 3.5rem;
  & ${StepLink} {
    margin-top: .25rem;
  }
  position: relative;
  font-size: 13px;
  user-select: none;
  ${Icon} {
    font-size: 20px;
    width: 1.25rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
  }
  ${props => props.selected ? css`
    background: ${props => desaturate(0.6, lighten(0.52, getColor("main")(props)))};;
    font-weight: 600;
    color: ${getColor('main')};
    position: relative;
    cursor: default;
    :before {
      content: '';
      width: 4px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: ${getColor('main')};
    }
    ${Icon} {
      color: ${getColor('main')};
    }
    .secondaryActionIcon {
      :hover {
        background: ${props => desaturate(0.5, lighten(0.45, getColor("main")(props)))} !important;
      }
      :active {
        background: ${props => desaturate(0.45, lighten(0.4, getColor("main")(props)))} !important;
      }
    }
  `:`
    :hover {
      cursor: pointer;
      background: rgba(0,0,0,0.05);
      .secondaryActionIcon {
        background: rgba(0,0,0,0.05);
      }
    }
  `}
  .secondaryActionIcon {
    cursor: pointer;
    right: 1rem;
    left: inherit;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%;
    pointer-events: auto;
    font-size: 23px;
    :hover {
      background: rgba(0,0,0,0.1);
    }
    :active {
      background: rgba(0,0,0,0.15);
    }
  }
  .notificationNumber {
    position: absolute;
    top: 8px;
    cursor: pointer;
    right: 1.5rem;
    left: inherit;
    min-width: 1.75rem;
    padding: 0 0.5rem;
    height: 1.75rem;
    line-height: 1.75rem;
    border-radius: 100px;
    pointer-events: auto;
    color: white;
    font-size: 12px;
    text-align: center;
    background: #c4212f;
    font-weight: 600;
  }
`
export const DashboardLink = styled(Link)`
  color: rgba(0,0,0,0.3);
  margin: 0 2rem 1.25rem;
  display: block;
`
export const SidebarDesc = styled.div`
  color: rgba(0,0,0,0.3);
  font-weight: 600;
  font-size: 11px;
  padding: 0.5rem 1.5rem 0.5rem;
`
export const SidebarInformation = styled.div`
  padding: 0rem 1.5rem 0.5rem;
`
export const SidebarTitle = styled.div`
  color: ${getColor('main')};
  padding: 0 1.5rem;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.5rem;
  :only-child {
    margin-bottom: 0;
  }
`
export const CaseId = styled.div`
  padding: 0 1.5rem;
  font-size: 12px;
  margin-bottom: 0.25rem;
`
export const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    ${props => props.responsiveWorkaround && css`
      // min-width: 1024px;
    `}
    ${props => props.noSidebar && css`
      // min-width: 640px;
      min-width: 320px;
    `}
    ${props => props.controlPanel && css`
      max-width: 100%;
      min-width: 710px;
    `}
`

export const PageContent = styled.div`
    flex-grow: 1;
    max-width: 66rem;
    ${props => props.controlPanel && css`
      max-width: 100%;
      min-width: 300px;
      flex-shrink: 1;
    `}
`

export const SidebarAsyncItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${getColor('gray210')};
  padding: 1rem 1rem 0 1rem;
  margin-top: 1rem;
  ${Button} {
   flex-grow: 1;
  }
  ${Button} ~ ${Button} {
    margin-left: .75rem;
  }
`

export const AsyncItemButton = styled(Button.Link)`
  ${props => props.selected && css`
    background: ${props => desaturate(0.6, lighten(0.52, getColor("main")(props)))};
    overflow: hidden;
    color: ${getColor('main')};
    font-weight: 600;
    box-shadow: none;
    pointer-events: none;
    user-select: none;
    &:before {
      border: 0px;
    }
  `}
`
export const SidebarSeparator = styled.div`
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: default;
    height: 0;
    ${props => props.withTitle && css`
      border: none;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      padding: 0 2rem 0 1.5rem;
      height: auto;
      span {
        text-transform: uppercase;
        color: rgba(0,0,0,0.3);
        font-weight: 600;
        font-size: 11px;
        margin-right: .5rem;
      }
      :after {
        flex-grow: 1;
        content: '';
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
    `}
`
const SmallEditButton = styled(Icon).attrs({icon:'edit'})`
  display: inline-block;
  border-radius: 1rem;
  line-height: 1.5rem;
  text-align: center;
  background: ${getColor('gray240')};
  padding: 0 .5rem;
  font-size: 12px;
  white-space: nowrap;
  min-width: 1.5rem;
  vertical-align: -1px;
  :hover {
    cursor: pointer;
    background: rgba(0,0,0,0.15);
  }
`

const TextButtonContribution = styled.a`
  text-decoration: none;
  color: ${getColor('main')};
  padding: 0.25rem 0.75rem;
  font-weight: 400;
  border-radius: 5px;
  user-select: none;
  position:relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  background-color: ${props => transparentize(0.9, getColor('main')(props))};
  & ~ & {
    margin-left: 0.75rem;
  }
  :hover {
    background-color: ${props => transparentize(0.85, getColor('main')(props))};
  }
  :active {
    background-color: ${props => transparentize(0.8, getColor('main')(props))};
  }
  ${props => props.focused && css`
    background-color: ${props => transparentize(0.9, getColor('main')(props))};
    color: ${getColor('main')};
  `}
  ${props => props.isLoading && css`
    background-color: ${props => transparentize(0.9, getColor('main')(props))};
    color: ${getColor('main')};
    pointer-events: none;
  `}
  ${props => props.disabled && css`
    color: ${getColor('gray90')};
    pointer-events: none;
  `}
  ${Icon} {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: bottom;
  }
`

export const ShareButtonDiv = styled.div`
  display: flex;
  padding: 0 1.5rem;
  margin-top: 0.75rem;
`

const selectIfCurrent = ({isCurrent}) => ({selected: true})

export const RequestSidebar = ({isCreatingCase, caseRequest = {}, setCaseRequest, panelToggle = {}, isReport = false}) => {

  const navigate = useNavigate()
  const confirmRedirect = preventDefault((e) => {
    // const href = e.target.href // Not work in this new
    // const href = e.target.getAttribute('href') // Works
    const href = e.target.pathname // Works and already used in other part of system
    confirmExitPage().then(() => navigate(href, {replace: false}))
  })
  
  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const adminIsReviewingThisCase = userInfo?.canReviewCases && caseRequest?.isAssignedToLoggedAdmin && (caseRequest?.state === UNDER_REVIEW || caseRequest?.state === WAITING_MORE_INFORMATION) && caseRequest?.activeCaseExpertType === REVIEWER_TYPE_ADMIN

  const editCategories = useEditCategories(userInfo,(caseRequest) => (categories) =>
    setCaseRequest(_.set('categories', categories))
  )

  const canAskForContribution = caseRequest && (
    caseRequest.state ===  CASE_DRAFT ||
    caseRequest.state ===  CASE_REVIEWED ||
    caseRequest.state ===  PENDING_ASSIGNMENT ||
    caseRequest.state ===  PENDING_REQUEST ||
    caseRequest.state ===  CASE_REJECTED ||
    caseRequest.state ===  REQUEST_REJECTED ||
    caseRequest.state ===  UNDER_REVIEW ||
    caseRequest.state ===  WAITING_ACCEPTANCE ||
    caseRequest.state ===  WAITING_APPROVAL ||
    caseRequest.state ===  WAITING_MEDICAL_RECORDS ||
    caseRequest.state ===  CASE_ARCHIVED ||
    caseRequest.state ===  WAITING_MORE_INFORMATION)

  const canShareCase = caseRequest && (
    caseRequest.state ===  CASE_CLOSED
  )

  const simpleShare = useReadOnlyShare(caseRequest)
  const [askContribution] = useAskContributionShare(caseRequest)
  const [shareCompleted] = useShareCompleted(caseRequest)

  const toggleAsPriority = useToggleAsPriority((caseRequest) =>
    setCaseRequest(_.update('markedAsPriorityEpochMilli',(v) => v ? null : Date.now()))
    // setList(_.map(item => item === caseRequest ? _.update('priority',(p)=>!p) : item))
  )
  const editCaseNumber = useEditCaseNumber((caseRequest) => (caseNumber) =>
      setCaseRequest(_.set('legalInfo',caseNumber))
  )

  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
  return <Match path="/admin/request/:requestCode/:step">{({match}) => {
    const step = match?.params && match.params.step
    return <SidebarContainer isReport={isReport}>
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard isPriority={caseRequest.markedAsPriorityEpochMilli}>
          {caseRequest.patientInfo ? <>
              <div className="sidebar-heading">
                <SidebarDesc>CASE DETAILS</SidebarDesc>
                <SidebarTitle className='fs-exclude'>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}</SidebarTitle>
                <IfLocale contains="LEGAL">
                  <CaseId><b>Case Number:</b> {
                      caseRequest?.legalInfo?.caseNumber?.length > 0 ? 
                      <span className="nowrap">{caseRequest.legalInfo.caseNumber} <SmallEditButton onClick={editCaseNumber.willOpenWith(caseRequest)}/></span>
                      : 
                      editCaseNumber 
                      ? <a className="light-link" onClick={editCaseNumber.willOpenWith(caseRequest)}>Add</a>
                      : null
                    }
                  </CaseId>
                </IfLocale>
                {/* {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>} */}
                {caseRequest.requestCode && <CaseId><b><FormattedMessage defaultMessage="Case Id:" description='RequestSidebar admin caseid item label'/></b> {caseRequest.requestCode}</CaseId>}
                <AdminCaseCardCategories
                  inSidebar
                  categories={caseRequest.categories}
                  quantityVisible={1}
                  whenEmpty={
                    <CategoryTag onClick={editCategories.willOpenWith(caseRequest)}>Edit categories</CategoryTag>
                  }
                  editCategories={editCategories.willOpenWith(caseRequest)}
                  userInfo={userInfo}
                />
                <div className="sidebar-icons">
                {/* COMMENTING THE OLD SHARE */}
                {providerInfo.readOnlyAccess && !canShareCase &&
                  <div onClick={simpleShare}>
                    <Tooltip content='Share case'>
                      <Icon icon='send-line'/>
                    </Tooltip>
                  </div>
                }
                <div className="priority-icon" onClick={toggleAsPriority.willOpenWith(caseRequest)}>
                  <Tooltip content={caseRequest.markedAsPriorityEpochMilli ? "Remove as priority" : "Mark as priority"}>
                    <Icon icon={caseRequest.markedAsPriorityEpochMilli ? "star" : "star-line"}/>
                  </Tooltip>
                </div>
                </div>


                {/* {providerInfo.readOnlyAccess && canAskForContribution && <ShareButtonDiv>
                  <TextButtonContribution onClick={askContribution}> <Icon icon="collaboration"/>Request collaboration</TextButtonContribution>
                </ShareButtonDiv>} */}
                
                {providerInfo.readOnlyAccess && canShareCase && <ShareButtonDiv>
                  <Button css={`width: 100%;`} highlight small onClick={shareCompleted} icon="send">
                    Share case
                  </Button>
                </ShareButtonDiv>}
                {/* <Button>Share</Button> */}
              </div>
              {caseRequest.state &&
              <>
                {caseRequest.state == CASE_ARCHIVED && <StepLink
                  onClick={confirmRedirect}
                  to="overview"
                  // disabled={isCurrentStateBefore(CASE_DRAFT)}
                  selected={step === "overview"}
                >
                  <Icon icon="audit"/>
                  Overview
                </StepLink>}
               <StepLink
                  onClick={confirmRedirect}
                  to="case-status"
                  // disabled={isCurrentStateBefore(CASE_DRAFT)}
                  selected={step === "case-status"}
                >
                  <Icon icon="dashboard"/>
                  Case status and activity
                </StepLink>
                <IfLocale not contains="LEGAL">
                  {providerInfo?.ehrHl7Enabled === true &&
                    <StepLink
                      onClick={confirmRedirect}
                      to="case-reconciliation"
                      // disabled={isCurrentStateBefore(CASE_DRAFT)}
                      selected={step === "case-reconciliation"}
                    >
                      <Icon icon="external-report"/> 
                      Case to EHR
                    </StepLink>
                  } 
                </IfLocale>
               <StepLink
                  onClick={confirmRedirect}
                  to="notes"
                  // disabled={isCurrentStateBefore(CASE_DRAFT)}
                  selected={step === "notes"}
                >
                  <Icon icon="study-notes"/>
                  Case notes
                  <Icon icon="open-panel" className="secondaryActionIcon" onClick={(e) => {panelToggle.openWith(true); e.preventDefault();e.stopPropagation() }}/>
                </StepLink>
                <SidebarSeparator withTitle>
                  <span>Actions</span>
                </SidebarSeparator>
                <StepLink
                  onClick={confirmRedirect}
                  to="contact"
                  selected={step === "contact"}
                >
                  <Icon icon="email-line"/>
                  Messaging
                  {caseRequest?.collaborationMessagesToRead > 0 && <span className="notificationNumber">
                    {caseRequest?.collaborationMessagesToRead}
                  </span>}
                </StepLink>
                <IfLocale not contains="LEGAL">
                  <StepLink
                    onClick={confirmRedirect}
                    to={!step ? "." : "payment"}
                    selected={step === "payment"}
                  >
                    <Icon icon="payment"/> Manage payments
                  </StepLink>

                  {(providerInfo?.serviceTerms === true) &&
                    <StepLink
                      onClick={confirmRedirect}
                      to="agreements"
                      selected={step === "agreements"}
                    >
                      <Icon icon="sign-doc"/> Review agreements
                    </StepLink>
                  }
                </IfLocale>

                <StepLink
                  onClick={confirmRedirect}
                  to="remote-consultation"
                  selected={step === "remote-consultation"}
                >
                  <Icon icon="video"/> Video consultation
                </StepLink>
                <SidebarSeparator withTitle>
                  <span>Steps</span>
                </SidebarSeparator>
              </>
              }
            </>
            :
            <>
              <SidebarTitle>New opinion request</SidebarTitle>
            </>
          }
          <StepLink
            onClick={confirmRedirect}
            to="patient"
            // disabled={caseRequest.state && isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "patient" || isCreatingCase}
          ><Icon icon="user-line"/><FormattedMessage defaultMessage="Patient & contact details" description='RequestSidebar patient item label'/></StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="releases"
            // disabled={isCurrentStateBefore(CASE_DRAFT)}
            selected={step === "releases"}
          ><Icon icon="edit-box"/> Locate medical records</StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="records"
            // disabled={isCurrentStateBefore(CASE_DRAFT)}
            selected={step === "records"}
          ><Icon icon="dicom-no-text"/> Medical records</StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="clinical"
            // disabled={isCurrentStateBefore(CASE_DRAFT)}
            selected={step === "clinical"}
          ><Icon icon="clinical-sheet"/><FormattedMessage defaultMessage="Clinical information" description='RequestSidebar clinical item label'/></StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="expert"
            disabled={isCurrentStateBefore(PENDING_ASSIGNMENT)}
            selected={step === "expert" || step === "waiting" || step === "rejected"}
          ><Icon icon="physician-line"/> Expert assignment</StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="review"
            disabled={isCurrentStateBefore(WAITING_MORE_INFORMATION) && !adminIsReviewingThisCase}
            selected={step === "review" || step === "waitingAdditional"}
          ><Icon icon="question-line"/> Expert review</StepLink>
          <StepLink
            onClick={confirmRedirect}
            to="report"
            disabled={isCurrentStateBefore(CASE_REVIEWED)}
            selected={step === "report" || step === "upload-report"}
          >{caseRequest.state === CASE_CLOSED ?
            <><Icon icon="checked-report"/> Final report</>
            : <><Icon icon="report"/> Case report</>}
          </StepLink>
          <IfLocale not contains="LEGAL">
            <StepLink
              onClick={confirmRedirect}
              to="continuing-care"
              // disabled={isCurrentStateBefore(CASE_REVIEWED)}
              selected={step === "continuing-care"}
            ><><Icon icon="hospital"/> Continuing care</>
            </StepLink>
          </IfLocale>
        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
  }
  }</Match>
}
