import React from 'react'
import _ from 'lodash/fp'
import {lazyProviderInfo} from '../../components/WithProvider'
import {Load} from '../../hooks/useSuspense'
import {lazyExperts} from '../../admin/steps/finalReport/reportPages/lazyExperts'
import {Preview} from './Preview'
import { CASE_CLOSED } from '../../enums/CaseState'


const fixExperts = (loadedExperts,isExpert) => (currentIds) => {
  if (!isExpert) {
    return currentIds
  }
  return _.unionBy(_.get('expertId'),loadedExperts.map(({id})=> ({expertId:id,showEdit:true})),currentIds)
}

export const PreviewForCaseRequest = ({
                                        caseRequest,
                                        isExpert,
                                        ...props
                                      }) => {
  if(caseRequest.state === CASE_CLOSED && caseRequest?.report?.expertReviews){

    const reportExperts = caseRequest.report.expertReviews
    
    // for each expert put specialization and resume into info object
    const adjustedExperts = reportExperts.map((expert) => {
      const {specialization, resume} = expert
      return {
        ...expert,
        info: {
          specialization,
          resume
        }
      }
    })

    return <Load value={lazyProviderInfo}>{providerInfo => {

        const studyCase = caseRequest.caseInfo
        const reportLayout = caseRequest.reportData.reportLayout
        const expertReviews = reportLayout.expertProfileDetails
          ? _.flow(
            fixExperts(adjustedExperts,isExpert),
            _.map((expertReview)=> _.assign(expertReview,(adjustedExperts||[]).find(({id}) => expertReview.expertId === id))),
            _.filter((expertReview)=> expertReview.id && !expertReview.hide),
            // filter by unique ids
            _.uniqBy(({id})=> id)
          // )(caseRequest.reportExpertReviews) // stopped using this, in order to use only caseRequest.report information.
          )(adjustedExperts) 
          : []

        // When opening the report, need to override the customColors in the reportData with the customColors in the providerInfo
        const reportData = _.update('customColors', () => providerInfo.customColors, caseRequest.reportData)

        const report = {
          additionalFields:studyCase.additionalFields,
          expertReviews,
          patientInfo: caseRequest.patientInfo,
          requestCode: caseRequest.requestCode,
          id: caseRequest.requestId,
          institutionDetails: caseRequest?.report?.institutionDetails || caseRequest.institutionDetails,
          questionsConsultant:studyCase.questionsConsultant,
          clinicalSummary:studyCase.clinicalSummary,
          caseSummary:studyCase.caseSummary,
          // reportData: caseRequest.reportData,
          reportData: reportData,
          signatures: studyCase.signatures,
          whenReturnedEpochMilli: caseRequest?.whenReturnedEpochMilli
        }

        return <Preview
          {...props}
          report={report}
          organization={{...providerInfo, ...providerInfo.address}}

        />
    }}</Load>

  }

  return <Load value={lazyProviderInfo}>{providerInfo => 
    <Load value={lazyExperts.use(_.union(caseRequest.reportExpertReviews,isExpert ? [{expertId:''}] : []))}>{experts => {

      const studyCase = caseRequest.caseInfo
      const reportLayout = caseRequest.reportData.reportLayout

      const expertReviews = reportLayout.expertProfileDetails
        ? _.flow(
          fixExperts(experts,isExpert),
          _.map((expertReview)=> _.assign(expertReview,(experts||[]).find(({id}) => expertReview.expertId === id))),
          _.filter((expertReview)=> expertReview.id && !expertReview.hide)
        )(caseRequest.reportExpertReviews)
        : []

      // When opening the report, need to override the customColors in the reportData with the customColors in the providerInfo
      const reportData = _.update('customColors', () => providerInfo.customColors, caseRequest.reportData)
      
      const report = {
        additionalFields:studyCase.additionalFields,
        expertReviews,
        patientInfo: caseRequest.patientInfo,
        requestCode: caseRequest.requestCode,
        id: caseRequest.requestId,
        institutionDetails: caseRequest?.report?.institutionDetails || caseRequest.institutionDetails,
        questionsConsultant:studyCase.questionsConsultant,
        clinicalSummary:studyCase.clinicalSummary,
        caseSummary:studyCase.caseSummary,
        // reportData: caseRequest.reportData,
        reportData: reportData,
        signatures: studyCase.signatures,
        whenReturnedEpochMilli: caseRequest?.whenReturnedEpochMilli
      }

      return <Preview
        {...props}
        report={report}
        organization={{...providerInfo, ...providerInfo.address}}

      />
    }}</Load>
  }</Load>
}