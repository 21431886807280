import {Loading} from '@startlibs/components';
import {getColor, isMobile, media} from '@startlibs/utils';
import React, {useRef, useState} from 'react';
import _ from 'lodash/fp';
import styled, {css} from 'styled-components'
import { CaseCategoryTags } from '../../dashboard/AdminCaseCardCategories';
import {StaffRow} from './StaffRow'
import {useConfirmStaffDialogs} from './useConfirmStaffDialogs'
import {useEditStaffCategories} from './useEditStaffCategories'
import { desaturate, lighten } from 'polished';

const StaffManagementTable = styled.div`
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  border-radius: 5px;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${StaffManagementTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.between(810,1024)`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
  ${media.max(810)`
    border: 0;
  `}
`

export const StaffManagementTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  background: white;
  flex-grow: 1;
  width: 100%;
  ${media.between(810,1024)`
    min-width: 774px;
  `}
  ${media.min(810)`
    :nth-child(even) {
      background: ${props => desaturate(0.65, lighten(0.545, getColor('main')(props)))};
    }
    :last-child {
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `}
  ${media.max(810)`
    display: block;
    margin-bottom: 0.75rem;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 1.5rem;
    position: relative;
  `}
`
export const StaffManagementTableHead = styled(StaffManagementTableRow)`
  align-items: center;
  font-weight: 600;
  background: white;
  top: 5.5rem;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  min-width: 774px;
  ${media.max(1024)`
    position: static;
  `}
  ${media.max(810)`
    display: none;
  `}
`
export const StaffManagementTableColumn = styled.div`
  flex-basis: 0;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  .not-case {
    color: ${getColor('gray150')};
  }
  &.user {
      flex-grow: 1;
      min-width: 100px;
      max-width: 180px;
      .name, .name > div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
      .role {
        color: ${getColor('gray150')};
        margin-top: -.25rem;
      }
  }
  &.email {
    flex-grow: 1;
    min-width: 120px;
    max-width: 180px;
    .id {
      color: ${getColor('gray150')};
    }
  }
  &.divisions {
    flex-grow: 0;
    max-width: 230px;
    min-width: 200px;
    ${CaseCategoryTags} {
      margin-top: 0;
    }
    .empty-divisions {
      color: ${getColor('gray150')};
      font-style: italic;
      padding-bottom: .25rem;
    }
  }
  &.status {
    flex-grow: 1;
    min-width: 85px;
    max-width: 140px;
  }
  &.reviewer {
    flex-grow: 1;
    min-width: 72px;
    max-width: 96px;
  }
  &.date {
    flex-grow: 1;
    min-width: 90px;
  }
  &.actions {
    flex-grow: 0;
    max-width: 100px;
    min-width: 100px;
  }
  ${media.max(810)`
    padding: 0;
    :before {
      font-weight: 600;
      display: block;
    }
    &.user {
      width: auto;
      max-width: 100%;
      padding-right: 95px;
      padding-left: 0;
      margin-bottom: .5rem;
      :before {
        content: "User: ";
        flex-basis: 100%;
        font-size: 13px;
      }
      .name {
        font-size: 16px;
      }
      .role {
        :before {
          content: "Role: "
        }
      }
    }
    &.email {
      margin-bottom: 0.5rem;
      font-size: 16px;
      padding-right: 125px;
      width: 100%;
      max-width: 100%;
      :before {
        content: "Email: ";
        font-size: 13px;
      }
    }
    &.divisions {
      margin-bottom: 0.5rem;
      width: auto;
      float: none;
      clear: both;
      :before {
        content: "Divisions: ";
      }
    }
    &.status {
      width: auto;
      margin-bottom: .5rem;
      min-width: 140px;
      max-width: 140px;
      margin-right: .5rem;
      :before {
        content: "Status: ";
      }
    }
    &.reviewer {
      width: auto;
      margin-bottom: .5rem;
      min-width: 72px;
      max-width: 96px;
      margin-right: .5rem;
      :before {
        content: "Reviewer: ";
      }
    }
    &.date{
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.05);
      padding: .25rem .5rem;
      text-align: right;
      min-width: auto;
      max-width: 85px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      :before {
        content: "Date added: ";
      }
      :after {
        content: "";
        clear: both;
        display: table;
      }
    }
    &.actions {
      max-width: 100%;
      width: auto;
      margin-top: 1rem;
      clear: both;
    }
  `}
`

const LoadingTableContent = styled.div`
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  height: 100%;
  height: calc(100% - 4rem);
  max-height: 80vh;
  max-height: calc(80vh - 4rem);
  ${props => !props.isMobile && css`
    ${media.desktop`
      max-width: calc(100vw - 5rem - 15px);
      left: 2.5rem;
    `}
    ${media.mobile`
      max-width: calc(100vw - 15px);
      left: 0;
    `}
  `}
`

const EmptyStudyList = styled.div`
  background: rgb(240,240,240);
  min-height: 8rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0
`

export const Table = ({setParams, refreshList, providerInfo, jwt, list, isLoading, editStaff}) => {

  const [staffList,setStaffList] = useState(list)
  const tableRef = useRef()

  const editCategories = useEditStaffCategories(refreshList)

  const confirmStaffDialogs = useConfirmStaffDialogs(refreshList)

  return <StaffManagementTable ref={tableRef} isLoading={isLoading} isMobile={isMobile()}>
    <StaffManagementTableHead isMobile={isMobile}>
      <StaffManagementTableColumn className="user">User</StaffManagementTableColumn>
      <StaffManagementTableColumn className="email">Email</StaffManagementTableColumn>
      <StaffManagementTableColumn className="divisions">Divisions</StaffManagementTableColumn>
      <StaffManagementTableColumn className="status">Status</StaffManagementTableColumn>
      <StaffManagementTableColumn className="reviewer">Reviewer</StaffManagementTableColumn>
      <StaffManagementTableColumn className="date">Date added</StaffManagementTableColumn>
      <StaffManagementTableColumn className="actions">Actions</StaffManagementTableColumn>
    </StaffManagementTableHead>
    {staffList.length ?
      staffList.map(record =>
        <StaffRow refreshList={refreshList} isLoading={isLoading}
                  key={record.id}
                  editStaff={editStaff}
                  editCategories={editCategories}
                  confirmStaffDialogs={confirmStaffDialogs}
                  record={record} isMobile={isMobile()}
        />)
      :
      <EmptyStudyList>{!isLoading && 'No users found'}</EmptyStudyList>
    }
    {isLoading &&
    <LoadingTableContent isMobile={isMobile()}><Loading absolute size={40} borderWidth={6}/></LoadingTableContent>}
  </StaffManagementTable>
}
