import { Button, DraggableItem, Icon, Loading } from '@startlibs/components';
import {_s} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import {
  InsertedSignatureItem,
  InsertedSignaturesWrapper,
} from '../../../../expert/ExpertSignatureManagement';
import { NotProvided } from '../../ExpertAssignmentStep';
import { ReportField } from './ReportFrontCover';
import { ReportTextButton } from './EditLayoutButton';
import {
  defaultListAnimation,
} from '../../../../UIConfig/defaultListAnimation';
import {getReportResource, multiWait} from '../../../../utils/utils'
import {SectionContent} from './ReportAdditionalFields'

export const ReportSignatures = ({caseRequest, updateCaseInfo, form}) => {

  const signatureSectionLoading = useToggle()
  const signatureLoading = useToggle([])

  const setItemOrder = (from, to) => {
    signatureSectionLoading.wait(updateCaseInfo(_.update('signatures',_s.moveElement(from,to))))
  }

  const toggleHide = (signature) => {
    multiWait(signatureLoading,updateCaseInfo(_.update('signatures',_.map(q => q.id === signature.id ? {...q,hide:!q.hide} : q))),signature.id)
  }

  const toggleSection = () => {
    form.setValue('reportLayout.expertSignatures',v => !v)
    signatureSectionLoading.wait(form.submitForm())
  }
  const isHidden = !caseRequest.reportData.reportLayout.expertSignatures

  return <><ReportField css={isHidden && "opacity: 0.5"}>
    <div className="field-heading">
      <h4>Signatures</h4>
      <div className="field-actions">
        {signatureSectionLoading.isOpen && <Loading size={16} borderWidth={3} />}
        <ReportTextButton small onClick={toggleSection}>
          {isHidden ?
            <><Icon icon="view" /> Show in report</>
          : <><Icon icon="hide" /> Hide from report</>
          }
        </ReportTextButton>
        <Button.Link small to={`/admin/request/${caseRequest.requestCode}/review`} state={{signatures:true}}>Edit</Button.Link>
      </div>
    </div>
    </ReportField>
    {caseRequest.caseInfo.signatures
      ? <InsertedSignaturesWrapper
        animation={defaultListAnimation}
        setItemOrder={setItemOrder}
        disabled={caseRequest.caseInfo.signatures?.length === 1}
      >
        {caseRequest.caseInfo?.signatures?.map(signature =>
          <DraggableItem
            key={signature.id}
            DragTag={SignatureItem}
            signature={signature}
            caseRequest={caseRequest}
            toggleHide={toggleHide}
            isHidden={isHidden}
            isLoading={signatureLoading.isOpen.indexOf(signature.id)>=0}
            isDraggable={caseRequest.caseInfo.signatures.length > 1}
          />)}
      </InsertedSignaturesWrapper>
      : <NotProvided/>
    }
  </>


}

export const SignatureItem = ({signature, toggleHide, resourceOrigin, onTouchStart, onMouseDown, children, className, isHidden, isLoading, isDraggable, isPreview}) => <>
  <InsertedSignatureItem
    css={(signature.hide || isHidden) && "opacity:0.5;"}
    onTouchStart={onTouchStart}
    onMouseDown={onMouseDown}
    className={className}
    inReport
    isPreview={isPreview}
    isDraggable={isDraggable && !isPreview}
    nameOnly={signature.signatureType === "NONE"}
    signAfter={signature.signatureType === "PEN"}
    img={signature.imagePath && getReportResource(signature.imagePath,resourceOrigin)}
  >

    <div className="signature-content">
      <div className="signature-image" role='img' aria-label={`Expert signature of ${signature.name}, ${signature.description}`}></div>
      <div className="signature-line"></div>
      <div className="display-name">{signature.name}</div>
      <div className="description">{signature.description}</div>
    </div>
    <div className="field-actions">
      {isLoading && <Loading size={16} borderWidth={3} />}
      {!isHidden && toggleHide && <ReportTextButton onClick={() => toggleHide(signature)}>
          {signature.hide ?
            <><Icon icon="view" /> Show in report</>
            : <><Icon icon="hide" /> Hide from report</>
          }
        </ReportTextButton>
      }
      {isDraggable && <Icon icon="move" className="move-icon" />}
    </div>
  </InsertedSignatureItem>
  {children}

</>
